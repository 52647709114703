@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Thin.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Thin.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Thin.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: "normal"; }

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Ultralight.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Ultralight.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Ultralight.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Ultralight.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Ultralight.ttf") format("truetype");
  font-weight: 200;
  font-style: "normal"; }

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Light.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Light.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Light.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: "normal"; }

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Regular.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Regular.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Regular.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: "normal"; }

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Medium.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Medium.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Medium.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: "normal"; }

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Semibold.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Semibold.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Semibold.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: "normal"; }

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Bold.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Bold.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Bold.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: "normal"; }

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Heavy.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Heavy.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Heavy.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Heavy.ttf") format("truetype");
  font-weight: 700;
  font-style: "normal"; }

@font-face {
  font-family: "SF UI Display";
  src: url("../fonts/SFUIDisplay/SFUIDisplay-Black.eot");
  src: local("SF UI Display"), url("../fonts/SFUIDisplay/SFUIDisplay-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIDisplay/SFUIDisplay-Black.woff2") format("woff2"), url("../fonts/SFUIDisplay/SFUIDisplay-Black.woff") format("woff"), url("../fonts/SFUIDisplay/SFUIDisplay-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: "normal"; }

@font-face {
  font-family: "Open Sans Condensed";
  src: url("../fonts/OpenSansCondensed/OpenSansCondensed-Light.eot");
  src: local("Open Sans Condensed"), url("../fonts/OpenSansCondensed/OpenSansCondensed-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSansCondensed/OpenSansCondensed-Light.woff2") format("woff2"), url("../fonts/OpenSansCondensed/OpenSansCondensed-Light.woff") format("woff"), url("../fonts/OpenSansCondensed/OpenSansCondensed-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: "normal"; }

html {
  overflow: auto;
  height: 100%; }

html,
body {
  position: relative;
  min-height: 100%; }

body {
  font-family: 'SF UI Display';
  color: #000000;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.35;
  letter-spacing: 0.03em;
  padding: 87px 0 64px; }
  @media (max-width: 1399px) {
    body {
      padding: 87px 0 34px; } }
  @media (max-width: 1199px) {
    body {
      font-size: 16px;
      line-height: 140%;
      padding: 63px 0 34px; } }
  @media (max-width: 767px) {
    body {
      padding: 63px 0 58px; } }

img, svg {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto; }

a {
  text-decoration: none; }

p {
  margin-bottom: 1em; }

b {
  font-weight: 600; }

.green-text {
  color: #478504; }

.light-blue-text {
  color: #29235C; }

.text-uppercase {
  text-transform: uppercase; }

.col, .col-auto, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm, .col-sm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xxl, .col-xxl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.container {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .row--with-grey-border {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #F8F8F8; }

@media (min-width: 0px) {
  .container {
    max-width: 540px; } }

@media (min-width: 0px) {
  .col, .col-auto, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm, .col-sm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xxl, .col-xxl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12 {
    flex: 1 1 0;
    max-width: 100%; } }

@media (min-width: 0px) {
  .col-auto {
    flex: 0 0 auto; } }

@media (min-width: 0px) {
  .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 0px) {
  .col-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 0px) {
  .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 0px) {
  .col-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 0px) {
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (min-width: 0px) {
  .col-offset-3 {
    margin-left: 25%; } }

@media (min-width: 0px) {
  .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 0px) {
  .col-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 0px) {
  .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 0px) {
  .col-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 0px) {
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 0px) {
  .col-offset-6 {
    margin-left: 50%; } }

@media (min-width: 0px) {
  .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 0px) {
  .col-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 0px) {
  .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 0px) {
  .col-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 0px) {
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%; } }

@media (min-width: 0px) {
  .col-offset-9 {
    margin-left: 75%; } }

@media (min-width: 0px) {
  .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 0px) {
  .col-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 0px) {
  .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 0px) {
  .col-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 0px) {
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 0px) {
  .col-offset-12 {
    margin-left: 100%; } }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 1 0;
    max-width: 100%; } }

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto; } }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 576px) {
  .col-sm-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 576px) {
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 576px) {
  .col-sm-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 576px) {
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (min-width: 576px) {
  .col-sm-offset-3 {
    margin-left: 25%; } }

@media (min-width: 576px) {
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 576px) {
  .col-sm-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 576px) {
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 576px) {
  .col-sm-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 576px) {
  .col-sm-offset-6 {
    margin-left: 50%; } }

@media (min-width: 576px) {
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 576px) {
  .col-sm-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 576px) {
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 576px) {
  .col-sm-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 576px) {
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; } }

@media (min-width: 576px) {
  .col-sm-offset-9 {
    margin-left: 75%; } }

@media (min-width: 576px) {
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 576px) {
  .col-sm-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 576px) {
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 576px) {
  .col-sm-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 576px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .container {
    max-width: 768px; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 1 0;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto; } }

@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768px) {
  .col-md-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 768px) {
  .col-md-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (min-width: 768px) {
  .col-md-offset-3 {
    margin-left: 25%; } }

@media (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 768px) {
  .col-md-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 768px) {
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 768px) {
  .col-md-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 768px) {
  .col-md-offset-6 {
    margin-left: 50%; } }

@media (min-width: 768px) {
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 768px) {
  .col-md-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 768px) {
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 768px) {
  .col-md-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 768px) {
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; } }

@media (min-width: 768px) {
  .col-md-offset-9 {
    margin-left: 75%; } }

@media (min-width: 768px) {
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 768px) {
  .col-md-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 768px) {
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 768px) {
  .col-md-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 1 0;
    max-width: 100%; } }

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto; } }

@media (min-width: 992px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 992px) {
  .col-lg-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 992px) {
  .col-lg-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (min-width: 992px) {
  .col-lg-offset-3 {
    margin-left: 25%; } }

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .col-lg-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 992px) {
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 992px) {
  .col-lg-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 992px) {
  .col-lg-offset-6 {
    margin-left: 50%; } }

@media (min-width: 992px) {
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 992px) {
  .col-lg-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 992px) {
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 992px) {
  .col-lg-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 992px) {
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; } }

@media (min-width: 992px) {
  .col-lg-offset-9 {
    margin-left: 75%; } }

@media (min-width: 992px) {
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 992px) {
  .col-lg-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 992px) {
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .col-lg-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 1 0;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto; } }

@media (min-width: 1200px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1200px) {
  .col-xl-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 1200px) {
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 1200px) {
  .col-xl-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 1200px) {
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (min-width: 1200px) {
  .col-xl-offset-3 {
    margin-left: 25%; } }

@media (min-width: 1200px) {
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 1200px) {
  .col-xl-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 1200px) {
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 1200px) {
  .col-xl-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 1200px) {
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 1200px) {
  .col-xl-offset-6 {
    margin-left: 50%; } }

@media (min-width: 1200px) {
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 1200px) {
  .col-xl-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 1200px) {
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1200px) {
  .col-xl-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 1200px) {
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; } }

@media (min-width: 1200px) {
  .col-xl-offset-9 {
    margin-left: 75%; } }

@media (min-width: 1200px) {
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 1200px) {
  .col-xl-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 1200px) {
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1400px) {
  .container {
    max-width: 1350px; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 1 0;
    max-width: 100%; } }

@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto; } }

@media (min-width: 1400px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 1400px) {
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 1400px) {
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-3 {
    margin-left: 25%; } }

@media (min-width: 1400px) {
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 1400px) {
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 1400px) {
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-6 {
    margin-left: 50%; } }

@media (min-width: 1400px) {
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 1400px) {
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 1400px) {
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-9 {
    margin-left: 75%; } }

@media (min-width: 1400px) {
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 1400px) {
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1400px) {
  .col-xxl-offset-12 {
    margin-left: 100%; } }

button, [role="button"], .btn {
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
  text-align: center;
  position: relative;
  align-content: center; }
  button svg, [role="button"] svg, .btn svg {
    display: block;
    max-width: 100%;
    max-height: 100%; }

.btn {
  padding: 19px 41px;
  background: #699830;
  border-radius: 30px;
  color: #FFFFFF;
  text-transform: uppercase;
  border: 1px solid #699830;
  transition: opacity .2s, background .2s, border .2s, color, .2s; }
  @media (max-width: 1199px) {
    .btn {
      padding: 15px 41px; } }
  .btn--next:after {
    content: "";
    display: block;
    width: 7px;
    height: 14px;
    background: url(/images/next.svg) 50% no-repeat;
    margin-left: 10px; }
  .btn--prev:before {
    content: "";
    display: block;
    width: 7px;
    height: 14px;
    background: url(/images/next.svg) 50% no-repeat;
    margin-right: 10px;
    transform: rotate(180deg); }
  .btn:hover {
    opacity: .6; }
  .btn--reverse {
    background: transparent;
    color: #699830; }
  .btn--reverse-green {
    border-color: #BDE28F;
    background: transparent;
    color: #BDE28F; }
  .btn--white {
    background: #FFFFFF;
    border-color: #FFFFFF;
    color: #000; }
    .btn--white.active {
      background: #f1baba;
      border-color: #f1baba; }
  .btn--smoke-white {
    background: rgba(255, 255, 255, 0.08);
    color: #FFFFFF;
    border-color: transparent; }
  .btn--c_green {
    color: #699830; }
  .btn--tt_none {
    text-transform: none; }
  .btn--base {
    padding: 14px 30px; }
  .btn--sm {
    padding: 10px 16px; }
  .btn--big {
    padding: 20px;
    min-height: 113px; }
  .btn--svg svg {
    position: initial;
    transform: none;
    margin: -10px 5px -10px 0; }
  .btn--wide {
    padding-right: 90px;
    padding-left: 90px; }
  .btn--fluid {
    max-width: 100%;
    width: 100%; }
  .btn--blue {
    background: #00437E;
    border: 1px solid #00437E; }
  .btn--ligth-blue {
    background: #2B7CBC;
    border: 1px solid #2B7CBC; }
  .btn--pdf {
    display: flex;
    width: 312px;
    height: 70px;
    padding: 0px;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid #478504;
    box-sizing: border-box;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #478504; }
    .btn--pdf svg {
      margin-left: 15px; }
    .btn--pdf:hover {
      color: #346006;
      border: 1px solid #346006; }
    @media (max-width: 992px) {
      .btn--pdf {
        font-size: 20px;
        width: 281px;
        height: 65px; } }
    @media (max-width: 768px) {
      .btn--pdf {
        font-size: 18px;
        line-height: 155%;
        width: 271px;
        height: 60px; } }

.article-head {
  margin: 0 0 56px; }
  @media (max-width: 767px) {
    .article-head {
      margin: 0 0 38px; } }

.article-title {
  text-align: center;
  font-weight: 500;
  font-size: 49px;
  line-height: 52px;
  color: #1D1E1F;
  margin: 0 0 15px; }
  @media (max-width: 767px) {
    .article-title {
      font-size: 22px;
      line-height: 135%; } }
  .article-title--left {
    text-align: left; }

.article-authors {
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;
  text-align: center;
  color: #1D1E1F;
  opacity: 0.5; }
  @media (max-width: 767px) {
    .article-authors {
      font-size: 16px; } }

.article-prev-picture {
  border-radius: 30px;
  background: center / cover no-repeat #a6a6a5;
  display: block;
  text-decoration: none;
  height: 421px;
  width: 100%;
  margin: 0 0 40px; }
  @media (max-width: 767px) {
    .article-prev-picture {
      height: 260px; } }

.article-preview {
  font-weight: 600;
  font-size: 26px;
  color: #2ABAEC;
  padding-bottom: 32px;
  position: relative;
  margin: 0 0 30px;
  display: block; }
  @media (max-width: 1199px) {
    .article-preview {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%; } }
  .article-preview:after {
    content: '';
    display: block;
    width: 82.0122%;
    height: 1px;
    background: #29235C;
    opacity: 0.5;
    position: absolute;
    left: 0;
    bottom: 0; }

.article blockquote, .article h2, .article h3 {
  font-weight: 600;
  font-size: 35px;
  line-height: 1.35;
  color: #29235C;
  margin-bottom: 16px; }
  @media (max-width: 767px) {
    .article blockquote, .article h2, .article h3 {
      font-size: 22px;
      margin-bottom: 8px; } }

.article * + h2, .article * + h3, .article * + h4 {
  margin-top: 24px; }
  @media (max-width: 767px) {
    .article * + h2, .article * + h3, .article * + h4 {
      margin-top: 16px; } }

.article h3 {
  font-size: 28px; }
  @media (max-width: 767px) {
    .article h3 {
      font-size: 20px; } }

.article h4 {
  color: #29235C;
  margin-bottom: 8px;
  font-size: inherit;
  font-weight: bold; }

.article img {
  margin: 40px auto; }

.article p {
  margin-bottom: 1em; }

.article ol {
  list-style: decimal; }

.article ul {
  list-style: disc; }

.article i {
  font-style: italic; }

.article b, .article strong {
  font-weight: bold; }

.article ul, .article ol {
  padding-left: 30px;
  margin: 0 0 1em; }

.article .table-responsive {
  overflow: auto;
  width: 100%;
  max-width: 100%; }

.article table {
  font-size: 0.8em;
  margin: 1em auto; }
  .article table th, .article table tr, .article table td {
    border: 2px solid #e3e3e3;
    padding: 3px 5px; }
  .article table th {
    font-weight: bold; }

.article blockquote {
  padding: 32px 0 35px;
  margin-bottom: 40px;
  position: relative;
  z-index: 1; }
  .article blockquote:before {
    content: '';
    display: block;
    width: 1000vw;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    background: #F8F8F8;
    transform: translateX(-50%);
    z-index: -1; }

.banner-drug-2 {
  display: block;
  align-content: center;
  padding: 0px 40px;
  position: relative; }
  @media (max-width: 768px) {
    .banner-drug-2 {
      padding: 0px 20px; } }
  .banner-drug-2--rounded {
    border-radius: 30px;
    padding-top: 85px;
    padding-bottom: 40px;
    min-height: 436px; }
    @media (max-width: 767px) {
      .banner-drug-2--rounded {
        border-radius: 25px;
        text-align: center;
        margin-left: -15px;
        margin-right: -15px;
        display: block;
        width: auto; } }
  .banner-drug-2--val {
    background-color: #A4D369; }
  .banner-drug-2--val-plus {
    background-color: #7FCEF6; }
  .banner-drug-2--gray {
    background: #F8F8F8; }
  .banner-drug-2__labels {
    margin: 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .banner-drug-2__title {
    margin-top: 110px;
    margin-bottom: 23px;
    font-style: normal;
    font-weight: 500;
    font-size: 69px;
    line-height: 70px;
    color: #29235C; }
    @media (max-width: 1200px) {
      .banner-drug-2__title {
        font-size: 51px;
        line-height: 58px;
        margin-top: 50px; } }
    @media (max-width: 992px) {
      .banner-drug-2__title {
        font-size: 43px; } }
    @media (max-width: 768px) {
      .banner-drug-2__title {
        margin-top: 24px;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 35px;
        line-height: 130%; } }
  .banner-drug-2__slogan {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 60px;
    text-decoration: none;
    display: block; }
    @media (max-width: 1200px) {
      .banner-drug-2__slogan {
        font-size: 25px; } }
    @media (max-width: 992px) {
      .banner-drug-2__slogan {
        font-size: 21px;
        margin-bottom: 40px; } }
    @media (max-width: 768px) {
      .banner-drug-2__slogan {
        font-size: 22px;
        line-height: 135%;
        margin-bottom: 0px; } }
    @media (max-width: 380px) {
      .banner-drug-2__slogan {
        font-size: 20px; } }
    .banner-drug-2__slogan--sm-m {
      margin-bottom: 16px; }
      @media (max-width: 767px) {
        .banner-drug-2__slogan--sm-m {
          margin-bottom: 16px; } }
  .banner-drug-2__author {
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
    color: #8E8F8F; }
  .banner-drug-2__link {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 18px;
    line-height: 155%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #2ABAEC;
    text-decoration: none; }
    .banner-drug-2__link svg {
      margin-left: 8px;
      display: block; }
  @media (max-width: 768px) {
    .banner-drug-2--rounded .banner-drug-2__slogan {
      margin-bottom: 25px; } }
  @media (max-width: 767px) {
    .banner-drug-2--rounded .banner-drug-2__slogan--sm-m {
      margin-bottom: 16px; } }
  .banner-drug-2__properties {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 92px;
    max-width: 550px; }
    @media (max-width: 1200px) {
      .banner-drug-2__properties {
        font-size: 18px; } }
    @media (max-width: 992px) {
      .banner-drug-2__properties {
        font-size: 16px; } }
    @media (max-width: 768px) {
      .banner-drug-2__properties {
        font-size: 18px;
        line-height: 140%;
        margin-top: 15px;
        margin-bottom: 32px; } }
    .banner-drug-2__properties__itm {
      padding-left: 32px;
      background-image: url(../img/icon-li-agree.svg);
      background-repeat: no-repeat;
      background-position: 0px 4px; }
  .banner-drug-2__buy-btn {
    margin-bottom: 115px; }
    @media (max-width: 1200px) {
      .banner-drug-2__buy-btn {
        margin-bottom: 75px; } }
    @media (max-width: 768px) {
      .banner-drug-2__buy-btn {
        text-align: center;
        margin-bottom: 48px; } }
  .banner-drug-2__img--absolute {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }
    @media (max-width: 767px) {
      .banner-drug-2__img--absolute {
        height: auto;
        position: relative; } }
    .banner-drug-2__img--absolute img {
      display: block;
      height: 100%;
      margin-left: auto; }
      @media (max-width: 767px) {
        .banner-drug-2__img--absolute img {
          height: auto;
          margin-right: auto; } }

.banner-drug {
  padding: 0px 40px; }
  @media (max-width: 768px) {
    .banner-drug {
      padding: 0px 20px; } }
  .banner-drug--val {
    background-color: #A4D369; }
  .banner-drug--val-plus {
    background-color: #7FCEF6; }
  .banner-drug__title {
    margin-top: 110px;
    font-style: normal;
    font-weight: 500;
    font-size: 69px;
    line-height: 70px;
    color: #29235C; }
    .banner-drug__title__description {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      color: #29235C;
      opacity: 0.5;
      margin-bottom: 23px; }
    @media (max-width: 1200px) {
      .banner-drug__title {
        font-size: 51px;
        line-height: 58px;
        margin-top: 50px; } }
    @media (max-width: 992px) {
      .banner-drug__title {
        font-size: 43px; } }
    @media (max-width: 768px) {
      .banner-drug__title {
        margin-top: 24px;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 35px;
        line-height: 130%; } }
  .banner-drug__slogan {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 60px; }
    @media (max-width: 1200px) {
      .banner-drug__slogan {
        font-size: 25px; } }
    @media (max-width: 992px) {
      .banner-drug__slogan {
        font-size: 21px;
        margin-bottom: 40px; } }
    @media (max-width: 768px) {
      .banner-drug__slogan {
        font-size: 22px;
        line-height: 135%;
        margin-bottom: 0px; } }
    @media (max-width: 380px) {
      .banner-drug__slogan {
        font-size: 20px; } }
  .banner-drug__properties {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 92px;
    max-width: 550px; }
    @media (max-width: 1200px) {
      .banner-drug__properties {
        font-size: 18px; } }
    @media (max-width: 992px) {
      .banner-drug__properties {
        font-size: 16px; } }
    @media (max-width: 768px) {
      .banner-drug__properties {
        font-size: 18px;
        line-height: 140%;
        margin-top: 15px;
        margin-bottom: 32px; } }
    .banner-drug__properties__itm {
      padding-left: 32px;
      background-image: url(../img/icon-li-agree.svg);
      background-repeat: no-repeat;
      background-position: 0px 4px; }
      .banner-drug__properties__itm--valemidin {
        background-image: url(../img/icon-li-agree--green.svg); }
  .banner-drug__buy-btn {
    margin-bottom: 115px; }
    @media (max-width: 1200px) {
      .banner-drug__buy-btn {
        margin-bottom: 75px; } }
    @media (max-width: 768px) {
      .banner-drug__buy-btn {
        text-align: center;
        margin-bottom: 48px; } }
    .banner-drug__buy-btn__description {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #1D1E1F;
      opacity: 0.8; }

.breadcrumbs {
  display: flex;
  margin-top: 72px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .breadcrumbs {
      margin-top: 16px;
      margin-bottom: 42px; } }
  .breadcrumbs__icon {
    align-self: center;
    margin-right: 13px; }
    @media (max-width: 767px) {
      .breadcrumbs__icon {
        margin-right: 10px; } }
    @media (max-width: 767px) {
      .breadcrumbs__icon svg {
        width: 4px;
        height: 8px; } }
  .breadcrumbs__separator {
    width: 4px;
    height: 4px;
    background: #1D1E1F;
    align-self: center;
    margin: 0px 13px; }
    @media (max-width: 767px) {
      .breadcrumbs__separator {
        display: none; } }
  .breadcrumbs a {
    font-weight: 500;
    color: #1D1E1F;
    opacity: 0.5;
    transition: 0.3s ease all; }
    .breadcrumbs a:hover {
      opacity: 0.8; }
  .breadcrumbs__itm {
    margin: 0px 13px;
    font-size: 22px;
    line-height: 135%; }
    @media (max-width: 1199px) {
      .breadcrumbs__itm {
        font-size: 18px;
        line-height: 140%;
        margin: 0px 8px; } }
    @media (max-width: 767px) {
      .breadcrumbs__itm {
        font-size: 14px;
        line-height: 140%;
        margin: 0px 6px; } }
    .breadcrumbs__itm:first-child {
      margin-left: 0px; }
    .breadcrumbs__itm--last {
      opacity: 1; }
      @media (max-width: 767px) {
        .breadcrumbs__itm--last {
          display: none; } }
      .breadcrumbs__itm--last:hover {
        opacity: 1; }

.breadcrumbs2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 72px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .breadcrumbs2 {
      margin-top: 16px;
      margin-bottom: 32px; } }
  .breadcrumbs2-item {
    margin-right: 12px;
    flex: 0 0 auto;
    max-width: 100%;
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    opacity: 0.5;
    transition: opacity .2s; }
    .breadcrumbs2-item:hover {
      opacity: .7; }
    .breadcrumbs2-item:not(a):hover {
      opacity: 1; }
    .breadcrumbs2-item:not(a) {
      opacity: 1; }
    @media (max-width: 767px) {
      .breadcrumbs2-item {
        font-size: 18px;
        line-height: 140%; } }
    .breadcrumbs2-item svg {
      margin-right: 12px; }
      @media (max-width: 767px) {
        .breadcrumbs2-item svg {
          width: 4px;
          margin-right: 8px; } }

.button {
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.04em;
  transition: 0.3s ease all;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 17px;
  line-height: 1.4;
  border-radius: 50px;
  text-transform: none;
  color: #1D1E1F;
  border: 1px solid #1D1E1F; }
  .button:hover:not(.active) {
    opacity: .7; }
  .button.active {
    background: #478504;
    color: #FFFFFF;
    border-color: #478504; }

.faq {
  padding: 80px 40px; }
  @media (max-width: 768px) {
    .faq {
      padding: 40px 15px 72px; } }
  .faq__title {
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    color: #29235C; }
    @media (max-width: 1200px) {
      .faq__title {
        font-size: 44px;
        line-height: 120%; } }
    @media (max-width: 992px) {
      .faq__title {
        font-size: 40px;
        line-height: 120%; } }
    @media (max-width: 768px) {
      .faq__title {
        font-weight: 600;
        font-size: 35px;
        line-height: 130%;
        margin-bottom: 24px; } }
  .faq__list li.q {
    background-color: #F8F8F8;
    border-radius: 30px;
    padding: 27px 62px 27px 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    cursor: pointer;
    margin-bottom: 8px;
    transition: 0.3s ease all;
    position: relative; }
    @media (max-width: 992px) {
      .faq__list li.q {
        padding: 21px 56px 21px 28px;
        font-size: 20px; } }
    @media (max-width: 768px) {
      .faq__list li.q {
        padding: 16px 56px 16px 21px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: #1D1E1F;
        margin-bottom: 12px; } }
    .faq__list li.q span {
      position: absolute;
      right: 27px;
      top: 50%;
      margin-top: -11px;
      width: 22px;
      height: 22px;
      display: block;
      background-image: url("../img/icon-plus.svg");
      background-repeat: no-repeat;
      transition: 0.3s ease all; }
    .faq__list li.q--active span {
      transform: rotate(45deg); }
  .faq__list li.a {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
    padding: 24px 40px;
    color: #1D1E1F;
    display: none; }
    @media (max-width: 768px) {
      .faq__list li.a {
        padding: 5px 30px 15px 30px;
        font-size: 16px;
        line-height: 130%; } }
  .faq__more {
    display: flex;
    justify-content: flex-end;
    margin-top: 45px; }
    @media (max-width: 768px) {
      .faq__more {
        margin-top: 25px;
        justify-content: center; } }
    .faq__more a {
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #29235C;
      align-items: center;
      text-decoration: none;
      transition: 0.3s ease all; }
      .faq__more a svg {
        margin-left: 16px;
        transition: 0.3s ease all; }
      .faq__more a:hover {
        transition: 0.3s ease all; }
        .faq__more a:hover svg {
          transition: 0.3s ease all;
          transform: translateX(-4px); }
      @media (max-width: 768px) {
        .faq__more a {
          font-size: 18px;
          line-height: 125%; } }
  .faq__page {
    padding: 20px 0px 80px; }
    .faq__page__itm {
      padding: 24px 0px;
      border-bottom: 1px solid #eae9ef; }
      .faq__page__itm__q {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 135%;
        color: #29235C;
        margin-bottom: 8px; }
        @media (max-width: 1399px) {
          .faq__page__itm__q {
            font-size: 25px; } }
        @media (max-width: 1199px) {
          .faq__page__itm__q {
            font-size: 24px; } }
        @media (max-width: 1199px) {
          .faq__page__itm__q {
            font-size: 22px; } }
      .faq__page__itm__a {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 155%;
        color: #1D1E1F; }
        @media (max-width: 1199px) {
          .faq__page__itm__a {
            font-size: 16px;
            line-height: 140%; } }

.help-block {
  position: relative; }
  .help-block--val {
    background-color: #f4e8f7;
    background-image: url("../img/logo-default-flowers.svg");
    background-repeat: no-repeat;
    background-position: 95% 10%; }
    @media (max-width: 768px) {
      .help-block--val {
        background-position: 50% 10%; } }
  .help-block__img {
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    max-width: 70%; }
    .help-block__img--val {
      background: #c88dd7; }
    @media (max-width: 768px) {
      .help-block__img {
        width: 100%;
        max-width: 100%;
        clip-path: polygon(0% 59%, 0% 100%, 100% 100%); } }
  .help-block__text {
    padding: 40px 42px 45px;
    position: absolute;
    right: 30%;
    bottom: 150px;
    border-radius: 25px;
    max-width: 590px;
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 52px; }
    @media (max-width: 2529px) {
      .help-block__text {
        right: 10%; } }
    @media (max-width: 1600px) {
      .help-block__text {
        font-size: 44px;
        line-height: 130%;
        right: 5%;
        padding: 30px 32px 35px; } }
    @media (max-width: 1200px) {
      .help-block__text {
        font-size: 32px;
        line-height: 119%;
        padding: 30px 23px 35px;
        max-width: 447px; } }
    @media (max-width: 992px) {
      .help-block__text {
        font-size: 29px;
        line-height: 130%; } }
    @media (max-width: 768px) {
      .help-block__text {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 135%;
        color: #FFFFFF; } }
    .help-block__text--val {
      background: linear-gradient(110.66deg, #9EC271 0.69%, #508909 79.07%);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      color: #FFFFFF; }
    .help-block__text:after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 70px solid #508909;
      border-left: 70px solid transparent;
      position: absolute;
      margin-top: 45px;
      right: 49px; }
      @media (max-width: 1600px) {
        .help-block__text:after {
          margin-top: 35px; } }

.inner {
  padding-bottom: 80px;
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;
  overflow: hidden; }
  @media (max-width: 767px) {
    .inner {
      padding-top: 24px;
      font-size: 18px; } }

.instruction {
  padding: 80px 40px; }
  @media (max-width: 768px) {
    .instruction {
      padding: 40px 15px 72px; } }
  .instruction__title {
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    color: #29235C;
    margin-bottom: 30px; }
    @media (max-width: 1200px) {
      .instruction__title {
        font-size: 44px;
        line-height: 120%; } }
    @media (max-width: 992px) {
      .instruction__title {
        font-size: 40px;
        line-height: 120%; } }
    @media (max-width: 768px) {
      .instruction__title {
        font-weight: 600;
        font-size: 35px;
        line-height: 130%;
        margin-bottom: 24px; } }

.label {
  text-decoration: none;
  padding: 9px 23px;
  border: 1px solid;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.35;
  color: #2ABAEC;
  border-radius: 100px;
  display: inline-block; }

.left-block {
  max-width: 672px; }
  .left-block--val {
    background-color: #A4D369; }
  .left-block--val-plus {
    background-color: #7FCEF6; }
  .left-block__itm {
    padding: 42px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    margin-bottom: 80px; }
    .left-block__itm__title {
      font-weight: 500;
      font-size: 49px;
      line-height: 52px;
      color: #29235C;
      margin-bottom: 25px; }
  .left-block__buy-block__buy-btn {
    margin-bottom: 15px; }
  .left-block__buy-block__disclamer {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #1D1E1F;
    opacity: 0.8;
    margin-bottom: 60px;
    max-width: 584px; }
  .left-block__buy-block__title {
    font-style: normal;
    font-weight: 500;
    font-size: 69px;
    line-height: 70px;
    color: #29235C; }
    .left-block__buy-block__title__block-2 {
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      color: #29235C;
      opacity: 0.5;
      margin-bottom: 16px; }
  .left-block__buy-block__slogan {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 8px; }
  .left-block__buy-block__price-descritpion {
    margin-bottom: 75px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
    color: #29235C; }

.tags__list {
  display: flex;
  flex-wrap: wrap; }
  .tags__list a {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    margin: 5px 10px;
    border: 1px solid #8E8F8F;
    border-radius: 50px;
    flex-wrap: wrap;
    padding: 7px 17px;
    text-decoration: none; }

.structure__list__itm {
  border-bottom: 1px solid #ccdcb6; }
  .structure__list__itm__visible {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer; }
  .structure__list__itm:last-child {
    border: none; }
  .structure__list__itm__icon {
    flex-basis: 56px;
    margin-right: 38px;
    overflow: hidden;
    background: #C88DD7;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .structure__list__itm__icon-plus {
      background: #FFA841; }
  .structure__list__itm__name {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    flex-grow: 1; }
  .structure__list__itm__description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
    color: #000000;
    padding-bottom: 10px;
    display: none; }
    .structure__list__itm__description p {
      margin-bottom: 0px; }
    .structure__list__itm__description ul {
      list-style: disc;
      margin-left: 23px; }
    .structure__list__itm__description b {
      font-weight: bold; }
  .structure__list__itm--active .structure__list__itm__plus {
    transform: rotate(45deg) !important; }
  .structure__list__itm--active .structure__list__itm__description {
    display: block; }

.news-card {
  margin: 0 0 60px;
  text-decoration: none;
  opacity: 1;
  display: block;
  transition: opacity .2s;
  color: #000000; }
  @media (max-width: 767px) {
    .news-card {
      margin-bottom: 40px; } }
  .news-card-img {
    border-radius: 30px;
    padding-top: 63.67925%;
    overflow: hidden;
    display: block;
    position: relative;
    background: center / cover no-repeat;
    margin: 0 0 16px; }
    @media (max-width: 1199px) {
      .news-card-img {
        padding-top: 50.72886%; } }
    .news-card-img--book {
      padding-top: 127.2%; }
  .news-card-body {
    font-size: 18px;
    line-height: 155%; }
  .news-card-title {
    margin: 0 0 8px;
    font-weight: 600;
    font-size: 26px;
    line-height: 135%;
    color: #1D1E1F; }
    @media (max-width: 991px) {
      .news-card-title {
        font-size: 22px; } }
  .news-card-author {
    font-size: 18px;
    line-height: 1.55;
    color: #8E8F8F;
    margin-top: 15px; }
    @media (max-width: 991px) {
      .news-card-author {
        margin-top: 8px; } }
  .news-card-datetime {
    min-height: 24px;
    padding-left: 34px;
    font-size: 18px;
    line-height: 155%;
    color: #8E8F8F;
    position: relative; }
    .news-card-datetime:before {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z' fill='%238E8F8F'/%3E%3Cpath d='M12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11.325L7.62901 13.437C7.40357 13.5778 7.24332 13.8024 7.18349 14.0614C7.12367 14.3204 7.16918 14.5926 7.31001 14.818C7.45084 15.0434 7.67545 15.2037 7.93444 15.2635C8.19343 15.3233 8.46557 15.2778 8.69101 15.137L12.531 12.737C12.6761 12.6461 12.7954 12.5195 12.8775 12.3692C12.9596 12.219 13.0018 12.0502 13 11.879V7C13 6.73478 12.8947 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6Z' fill='%238E8F8F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); }
  .news-card-margin {
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .news-card-margin {
        margin-bottom: 40px; } }
  .news-card-container {
    margin-bottom: -60px; }
    @media (max-width: 767px) {
      .news-card-container {
        margin-bottom: -40px; } }
  .news-card:hover {
    opacity: .7; }

/*# sourceMappingURL=bootstrap-grid.css.map */
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav .owl-next[role="button"], .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav .owl-prev[role="button"], .owl-carousel button.owl-dot, .owl-carousel .owl-dot[role="button"] {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item .owl-lazy:not([src]), .owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

.page-404 {
  text-align: center;
  margin-top: 126px;
  margin-bottom: 146px; }
  @media (max-width: 768px) {
    .page-404 {
      margin-top: 152px; } }
  .page-404__numbers {
    font-style: normal;
    font-weight: bold;
    font-size: 275.314px;
    line-height: 329px;
    color: #BDE28F;
    display: flex;
    justify-items: center;
    justify-content: center; }
    .page-404__numbers img {
      transform: translateY(17px); }
      @media (max-width: 768px) {
        .page-404__numbers img {
          width: 120px; } }
    @media (max-width: 768px) {
      .page-404__numbers {
        font-size: 150px;
        line-height: 179px; } }
  .page-404__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 56px;
    font-weight: 600;
    font-size: 26px;
    line-height: 135%;
    color: #1D1E1F; }
    @media (max-width: 768px) {
      .page-404__text {
        font-size: 22px; } }
    .page-404__text img {
      margin-left: 7px; }

.prep-sticky-block {
  margin-top: 45px;
  margin-bottom: 45px;
  position: sticky;
  top: 100px;
  padding: 55px 40px;
  border-radius: 30px;
  text-align: center; }
  .prep-sticky-block--val {
    background: #BDE28F; }
  .prep-sticky-block--val-plus {
    background: #7FCEF6; }
  @media (max-width: 1399px) {
    .prep-sticky-block {
      padding: 35px 20px; } }
  @media (max-width: 1199px) {
    .prep-sticky-block {
      padding: 25px 10px; } }
  .prep-sticky-block__name {
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    text-align: center;
    color: #29235C;
    margin-bottom: 8px; }
    @media (max-width: 1399px) {
      .prep-sticky-block__name {
        font-size: 45px;
        line-height: 47px; } }
    @media (max-width: 1199px) {
      .prep-sticky-block__name {
        font-size: 40px;
        line-height: 43px; } }
    .prep-sticky-block__name span {
      color: #699830; }
  .prep-sticky-block__description {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #29235C;
    opacity: 0.5;
    margin-bottom: 8px; }
  .prep-sticky-block__img {
    text-align: center;
    margin-bottom: 35px; }
    .prep-sticky-block__img--val {
      background-image: url("../img/heart.svg");
      background-repeat: no-repeat;
      background-position: 0px 20px; }
      @media (max-width: 1399px) {
        .prep-sticky-block__img--val {
          background-size: contain; } }
    .prep-sticky-block__img--val-plus {
      background-image: url("../img/heart-plus.svg");
      background-repeat: no-repeat;
      background-position: 0px 20px; }
      @media (max-width: 1399px) {
        .prep-sticky-block__img--val-plus {
          background-size: contain; } }
    .prep-sticky-block__img img {
      margin: 0px auto; }

.read-more {
  margin-top: 60px; }

.rounded-banner {
  background: center / cover no-repeat #BDE28F;
  display: block;
  text-decoration: none;
  color: #FFFFFF;
  border-radius: 30px;
  padding: 105px 40px;
  opacity: 1;
  min-height: 100%;
  transition: opacity .2s; }
  @media (max-width: 767px) {
    .rounded-banner {
      min-height: 366px;
      padding: 45px 40px;
      margin: 0 -15px;
      border-radius: 0; } }
  .rounded-banner__title {
    font-weight: 500;
    font-size: 49px;
    line-height: 1.06;
    text-align: center; }
    @media (max-width: 1199px) {
      .rounded-banner__title {
        font-size: 26px;
        line-height: 1.35; } }
  .rounded-banner:hover {
    opacity: .7; }

.sertifications {
  margin-bottom: 60px; }
  .sertifications__itm {
    display: flex;
    padding: 24px 0px;
    align-items: center;
    border-bottom: 1px solid #eae9ef; }
    .sertifications__itm:last-child {
      border-bottom: none; }
    .sertifications__itm__text-link {
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 135%;
      color: #29235C;
      flex-grow: 1;
      transition: 0.3s ease all;
      text-align: left; }
      @media (max-width: 1399px) {
        .sertifications__itm__text-link {
          font-size: 24px; } }
      @media (max-width: 1199px) {
        .sertifications__itm__text-link {
          font-size: 21px; } }
      @media (max-width: 767px) {
        .sertifications__itm__text-link {
          font-size: 18px;
          line-height: 140%;
          text-align: left; } }
      .sertifications__itm__text-link:hover {
        opacity: 0.8; }
    .sertifications__itm__text-link-download {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #699830;
      flex: none;
      order: 1;
      flex-grow: 0; }
      .sertifications__itm__text-link-download:hover {
        text-decoration: underline; }
    .sertifications__itm__icon {
      width: 88px;
      height: 88px;
      margin-right: 36px;
      background: #699830;
      border-radius: 30px;
      justify-content: center;
      align-items: center;
      display: flex; }
      @media (max-width: 767px) {
        .sertifications__itm__icon {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          margin-right: 14px;
          border-radius: 50%; } }
      @media (max-width: 767px) {
        .sertifications__itm__icon svg {
          width: 13px;
          height: 15px; } }

.tabs {
  border-bottom: 1px solid rgba(41, 35, 92, 0.5);
  display: flex;
  align-items: stretch;
  justify-content: flex-start; }

.tab {
  padding: 15px 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;
  color: #29235C;
  opacity: 0.5;
  text-align: center;
  margin-bottom: -1.5px;
  cursor: pointer; }
  @media (max-width: 767px) {
    .tab {
      font-size: 18px; } }
  .tab.active {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #29235C !important;
    border-bottom: 3px solid !important;
    opacity: 1;
    transition: 0.3s ease all; }
    @media (max-width: 767px) {
      .tab.active {
        font-size: 18px; } }
  @media (max-width: 1199px) {
    .tab {
      padding: 15px 20px; } }
  @media (max-width: 767px) {
    .tab {
      padding: 15px 10px; } }
  .tab:first-child {
    padding-left: 0px; }
  .tab_item {
    margin-top: 49px;
    display: none; }
    .tab_item:first-child {
      display: block; }

.tests {
  font-weight: 500;
  font-size: 22px;
  line-height: 135%; }
  @media (max-width: 767px) {
    .tests {
      font-size: 18px; } }
  .tests-title {
    color: #29235C;
    margin: 0 0 50px; }
    @media (max-width: 767px) {
      .tests-title {
        font-size: 35px;
        line-height: 130%; } }
  .tests-list {
    padding: 0 0 30px; }
  .tests-card {
    margin: 0 0 30px;
    text-decoration: none;
    opacity: 1;
    display: block;
    transition: opacity .2s; }
    .tests-card-img {
      border-radius: 30px;
      position: relative;
      padding: 31px 33px;
      height: 270px;
      overflow: hidden;
      display: block;
      position: relative;
      margin: 0 0 16px;
      color: #fff; }
      @media (max-width: 767px) {
        .tests-card-img {
          height: 174px;
          padding: 17px; } }
      .tests-card-img--valemidin {
        background: #2B7CBC; }
        .tests-card-img--valemidin__img {
          width: 298px;
          height: 165px;
          position: absolute;
          bottom: 16px;
          right: 16px; }
          @media (max-width: 1399px) {
            .tests-card-img--valemidin__img {
              width: 198px;
              height: 109px; } }
          @media (max-width: 991px) {
            .tests-card-img--valemidin__img {
              width: 145px;
              height: 94px; } }
          @media (max-width: 767px) {
            .tests-card-img--valemidin__img {
              bottom: 6px; } }
      .tests-card-img--morin {
        background: linear-gradient(80.61deg, #478504 6.53%, #6CBE13 90.53%); }
        .tests-card-img--morin__img {
          position: absolute;
          bottom: 32px;
          right: 32px; }
          @media (max-width: 767px) {
            .tests-card-img--morin__img {
              bottom: 17px;
              right: 17px; } }
      .tests-card-img--scale {
        background: linear-gradient(77.05deg, #3F044F 7.35%, #823297 127.92%); }
        .tests-card-img--scale__img {
          position: absolute;
          bottom: 32px;
          right: 32px; }
          @media (max-width: 767px) {
            .tests-card-img--scale__img {
              bottom: 17px;
              right: 17px; } }
      .tests-card-img--bek {
        background: linear-gradient(243.98deg, #2B3B8B 8.16%, #29235C 99.25%); }
        .tests-card-img--bek__img {
          position: absolute;
          bottom: 32px;
          right: 32px; }
          @media (max-width: 991px) {
            .tests-card-img--bek__img svg {
              width: 145px; } }
          @media (max-width: 767px) {
            .tests-card-img--bek__img {
              bottom: 17px;
              right: 17px; } }
      .tests-card-img img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .tests-card-title {
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      line-height: 135%;
      color: #F8F8F8; }
      @media (max-width: 1199px) {
        .tests-card-title {
          font-size: 28px; } }
      @media (max-width: 767px) {
        .tests-card-title {
          font-size: 22px; } }
    .tests-card-datetime {
      padding: 4px 10px 4px 44px;
      position: absolute;
      height: 36px;
      left: 28px;
      bottom: 32px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 29px;
      font-size: 18px;
      line-height: 155%; }
      @media (max-width: 767px) {
        .tests-card-datetime {
          height: 20px;
          left: 17px;
          bottom: 17px;
          font-size: 14px;
          padding: 0px 10px 0px 30px; } }
      .tests-card-datetime:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: 6px;
        left: 10px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z' fill='%23fff'/%3E%3Cpath d='M12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11.325L7.62901 13.437C7.40357 13.5778 7.24332 13.8024 7.18349 14.0614C7.12367 14.3204 7.16918 14.5926 7.31001 14.818C7.45084 15.0434 7.67545 15.2037 7.93444 15.2635C8.19343 15.3233 8.46557 15.2778 8.69101 15.137L12.531 12.737C12.6761 12.6461 12.7954 12.5195 12.8775 12.3692C12.9596 12.219 13.0018 12.0502 13 11.879V7C13 6.73478 12.8947 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6Z' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); }
        @media (max-width: 767px) {
          .tests-card-datetime:before {
            width: 14px;
            height: 14px;
            top: 3px;
            left: 9px; } }
    .tests-card__description {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 155%;
      color: #1D1E1F; }
    .tests-card:hover {
      opacity: .7; }
      .tests-card:hover__description {
        color: #1D1E1F; }
  .tests-test {
    color: #FFFFFF;
    border-radius: 30px;
    padding: 40px 60px 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    background: #E58E7D;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 0 56px; }
    @media (max-width: 767px) {
      .tests-test {
        padding: 20px 15px;
        margin: 0 -15px 56px; } }
    .tests-test-title {
      font-weight: 500;
      font-size: 49px;
      line-height: 52px;
      margin: 0 auto 45px;
      width: 100%;
      padding: 0 230px;
      position: relative; }
      @media (max-width: 1229px) {
        .tests-test-title {
          padding: 0; } }
      @media (max-width: 767px) {
        .tests-test-title {
          font-size: 26px;
          line-height: 135%;
          margin: 0 auto 15px; } }
      .tests-test-title:before, .tests-test-title:after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        clear: both; }
    .tests-test-all {
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #FFFFFF;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      position: absolute;
      left: 0;
      top: 0;
      padding: 6px 24px;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 20px; }
      @media (max-width: 1229px) {
        .tests-test-all {
          position: relative;
          margin: 28px auto 4px;
          width: 270px;
          justify-content: center;
          padding: 16px 42px;
          font-weight: 500;
          font-size: 18px;
          line-height: 155%;
          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase; } }
      .tests-test-all svg {
        margin-left: 8px; }
    .tests-test-pager {
      display: inline-block;
      padding: 4px 14px;
      background: rgba(255, 255, 255, 0.2) !important;
      border-radius: 36px;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      margin: 0 auto 40px; }
      @media (max-width: 767px) {
        .tests-test-pager {
          font-size: 18px;
          margin: 0 auto 10px; } }
      .tests-test-pager__del, .tests-test-pager__end {
        opacity: .5; }
    .tests-test-question {
      font-weight: 600;
      font-size: 35px;
      line-height: 135%;
      margin: 0 auto 15px; }
      @media (max-width: 767px) {
        .tests-test-question {
          font-size: 22px;
          margin: 0 auto 25px; } }
    .tests-test-answers {
      width: 100%; }
    .tests-test-answer {
      min-height: 100%;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      color: #29235C;
      background: #FFFFFF;
      border-radius: 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
      text-align: center;
      text-decoration: none;
      opacity: 1;
      transition: opacity .2s;
      margin: 0 0 25px; }
      @media (max-width: 767px) {
        .tests-test-answer {
          flex-direction: row;
          min-height: 110px;
          font-size: 18px;
          line-height: 140%;
          margin: 0 0 12px;
          text-align: left; } }
      .tests-test-answer-img {
        background: center / cover no-repeat;
        padding-top: 60%;
        display: block;
        border-radius: 30px;
        overflow: hidden; }
        @media (max-width: 767px) {
          .tests-test-answer-img {
            flex: 0 0 179px;
            padding-top: 0;
            align-self: stretch; } }
      .tests-test-answer-text {
        margin: auto;
        padding: 20px 25px 20px; }
        @media (max-width: 767px) {
          .tests-test-answer-text {
            flex: 1 1 0; } }
      .tests-test-answer:hover {
        opacity: .7; }
      .tests-test-answer--center {
        text-align: center;
        min-height: auto;
        background: white; }
  .tests-more {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 42px;
    border: 1px solid #478504;
    box-sizing: border-box;
    border-radius: 30px;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    max-width: 310px;
    color: #478504;
    cursor: pointer;
    transition: opacity .2s;
    margin: 0 auto; }
    .tests-more:hover {
      opacity: .7; }
    @media (max-width: 767px) {
      .tests-more {
        padding: 16px 42px;
        font-size: 18px;
        border-radius: 25px; } }
  .tests-back {
    display: flex;
    text-decoration: none;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    opacity: 0.5;
    margin: 0 0 56px; }
    @media (max-width: 767px) {
      .tests-back {
        font-size: 18px;
        line-height: 140%;
        margin: 0 0 32px; } }
    .tests-back svg {
      margin-right: 12px; }
      @media (max-width: 767px) {
        .tests-back svg {
          width: 4px;
          margin-right: 8px; } }
  .tests-faq {
    border-top: 1px solid rgba(41, 35, 92, 0.08);
    border-bottom: 1px solid rgba(41, 35, 92, 0.08); }
    .tests-faq li.q {
      font-weight: 600;
      font-size: 22px;
      line-height: 135%;
      color: #29235C;
      background-color: transparent;
      border-radius: 0; }
      .tests-faq li.q ~ li.q {
        border-top: 1px solid rgba(41, 35, 92, 0.08); }
  .tests .tabs__wrapper {
    display: flex; }
  .tests .dop-res, .tests .test-res-var {
    display: none; }
  .tests .ans-button {
    margin: 0 8px 0; }
  .tests .ans-wrap {
    justify-content: center;
    align-items: center; }
  .tests .tests-test-answer--center {
    background: white; }
  .tests .ans-button.sel > a {
    background: #f1baba; }
  .tests .ans-next > a {
    background: #478504;
    text-transform: uppercase;
    color: white;
    position: relative; }
    .tests .ans-next > a > div {
      padding: 20px 40px; }
    .tests .ans-next > a:after {
      content: ' ';
      display: block;
      width: 7px;
      height: 14px;
      background: url("/images/next.svg") 50% no-repeat;
      position: absolute;
      right: 24px;
      top: 27px; }
  .tests .tests-test {
    background: url("/images/heart.png") 50% -200px no-repeat, linear-gradient(243.98deg, #2B3B8B 8.16%, #29235C 99.25%) !important; }
  .tests .test-body--ending {
    text-align: left;
    max-width: 80%;
    padding: 0; }

.tests-test.tests-cats {
  background: #E58E7D !important; }

.tests-cats {
  position: relative; }
  .tests-cats .b-heart-1 {
    position: absolute;
    top: 85px;
    left: 106px; }
    @media (max-width: 767px) {
      .tests-cats .b-heart-1 {
        display: none; } }
  .tests-cats .b-heart-2 {
    position: absolute;
    top: 130px;
    left: 230px; }
    @media (max-width: 767px) {
      .tests-cats .b-heart-2 {
        display: none; } }
  .tests-cats .b-heart-3 {
    position: absolute;
    top: 80px;
    right: 80px; }
    @media (max-width: 767px) {
      .tests-cats .b-heart-3 {
        display: none; } }
  .tests-cats .test-body--ending {
    max-width: 100%;
    display: flex; }
    .tests-cats .test-body--ending > div {
      width: 50%; }
    @media (max-width: 991px) {
      .tests-cats .test-body--ending {
        flex-direction: column; }
        .tests-cats .test-body--ending > div {
          width: 100%; } }
  .tests-cats .tests-test-answers-inner > div {
    margin-bottom: 25px; }
  .tests-cats .tests-test-answers {
    max-width: 900px; }
  .tests-cats .tests-test-answer {
    margin-bottom: 0;
    height: 100%; }
  @media (max-width: 767px) {
    .tests-cats .tests-test-answer-img {
      background-size: cover; } }
  @media (max-width: 575px) {
    .tests-cats .tests-test-answer-img {
      flex: 0 0 140px; } }
  @media (max-width: 575px) {
    .tests-cats .tests-test-answer-text {
      padding: 20px 10px;
      font-size: 16px;
      line-height: 1.2; } }

.tests-cats-result {
  padding: 0; }
  .tests-cats-result .b-heart-1, .tests-cats-result .b-heart-2, .tests-cats-result .b-heart-3 {
    display: none; }
  @media (max-width: 767px) {
    .tests-cats-result .test-header--ending {
      text-align: center; }
      .tests-cats-result .test-header--ending > div {
        width: 100%; } }
  .tests-cats-result .test-body__inner-left {
    padding: 40px; }
    .tests-cats-result .test-body__inner-left .test-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
    @media (max-width: 767px) {
      .tests-cats-result .test-body__inner-left {
        padding: 20px; } }
  @media (max-width: 991px) {
    .tests-cats-result {
      max-width: 687px; } }

h1, h2, h3 .h1, .h2, .h3 {
  font-style: normal;
  font-weight: 500; }

.h4, .h5, .h6, h4, h5, h6 {
  font-style: normal;
  font-weight: 600; }

h1, .h1 {
  font-weight: 500;
  font-size: 96px;
  line-height: 115px; }

h2, .h2 {
  font-size: 69px;
  line-height: 70px;
  margin-bottom: 40px; }
  @media (max-width: 1199px) {
    h2, .h2 {
      font-size: 57px; } }
  @media (max-width: 991px) {
    h2, .h2 {
      font-size: 45px;
      line-height: 130%; } }
  @media (max-width: 767px) {
    h2, .h2 {
      font-size: 35px;
      line-height: 130%;
      margin-bottom: 30px; } }

h3, .h3 {
  font-size: 49px;
  line-height: 52px; }
  @media (max-width: 767px) {
    h3, .h3 {
      font-weight: 600;
      font-size: 35px;
      line-height: 130%; } }

h4, .h4 {
  font-size: 35px;
  line-height: 135%;
  margin-top: 30px;
  margin-bottom: 16px; }
  @media (max-width: 1199px) {
    h4, .h4 {
      font-size: 28px; } }
  @media (max-width: 991px) {
    h4, .h4 {
      font-size: 22px; } }
  @media (max-width: 767px) {
    h4, .h4 {
      font-size: 18px;
      line-height: 140%; } }

h5, .h5 {
  font-size: 26px;
  line-height: 135%; }

h6, .h6 {
  font-size: 22px;
  line-height: 135%; }

.text-dark-blue {
  color: #29235C; }

.text-dark-green {
  color: #478504; }

.recommendation {
  display: flex;
  align-items: center;
  margin-bottom: 24px; }
  .recommendation__itm {
    display: flex;
    align-items: center;
    margin-right: 40px; }
    .recommendation__itm svg {
      margin-right: 15px; }

.text {
  font-weight: 500;
  font-size: 22px;
  line-height: 135%; }
  .text ul {
    list-style: disc;
    margin-left: 20px; }
  @media (max-width: 1199px) {
    .text {
      font-size: 21px; } }
  @media (max-width: 991px) {
    .text {
      font-size: 20px; } }
  @media (max-width: 767px) {
    .text {
      font-size: 18px;
      line-height: 130%; } }

.use-information {
  max-width: 882px;
  margin: 0px auto 40px;
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;
  color: #1D1E1F;
  text-align: left; }
  @media (max-width: 1199px) {
    .use-information {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #1D1E1F; } }
  @media (max-width: 767px) {
    .use-information {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      color: #1D1E1F; } }
  .use-information h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    color: #29235C;
    margin-bottom: 46px; }
    @media (max-width: 767px) {
      .use-information h1 {
        font-weight: 500;
        font-size: 22px;
        line-height: 135%;
        margin-bottom: 32px;
        text-align: center; } }
    @media (max-width: 767px) {
      .use-information h1 {
        font-weight: 500;
        font-size: 22px;
        line-height: 135%;
        margin-bottom: 32px;
        text-align: center; } }
  .use-information ol {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: #1D1E1F;
    list-style: auto;
    margin-left: 30px; }
    @media (max-width: 767px) {
      .use-information ol {
        margin-left: 20px; } }
    .use-information ol li {
      margin-bottom: 20px; }
      @media (max-width: 767px) {
        .use-information ol li {
          margin-bottom: 10px; } }
      .use-information ol li ul {
        list-style: disc;
        margin-top: 20px;
        margin-left: 60px; }
        @media (max-width: 767px) {
          .use-information ol li ul {
            margin-top: 10px;
            margin-left: 40px; } }
        .use-information ol li ul li {
          margin-bottom: 3px; }

.v-help {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  padding: 74px 0 48px;
  position: relative; }
  @media (max-width: 1199px) {
    .v-help {
      padding: 77px 0 0;
      align-items: center; } }
  .v-help-bg {
    background: #C27ED3;
    position: relative;
    overflow: hidden; }
  .v-help-rect {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 50%; }
    @media (max-width: 1199px) {
      .v-help-rect {
        top: 0;
        width: 100%;
        left: 0;
        right: 0;
        height: 50%; } }
  .v-help-img {
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    width: auto;
    max-width: none;
    transform: translateX(-50%); }
    @media (max-width: 1199px) {
      .v-help-img {
        position: initial;
        max-width: none;
        height: auto;
        transform: none;
        margin-top: -110px; } }
  .v-help-message {
    z-index: 1;
    padding: 35px 35px 90px;
    font-size: 49px;
    line-height: 1.06;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    width: 583px;
    max-width: 100%; }
    @media (max-width: 1199px) {
      .v-help-message {
        width: 335px;
        font-weight: 600;
        font-size: 26px;
        line-height: 1.2;
        padding: 35px 35px 57px; } }
    .v-help-message svg, .v-help-message img {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 100%;
      z-index: -1; }
      @media (max-width: 1199px) {
        .v-help-message svg, .v-help-message img {
          top: 0;
          right: 0; } }
    .v-help-message--1 {
      padding: 64px 35px 65px 48px; }
      @media (max-width: 1199px) {
        .v-help-message--1 {
          padding: 35px 35px 57px; } }
  .v-help-logo {
    margin-bottom: 60px;
    position: relative;
    max-width: 100%; }
    @media (max-width: 1199px) {
      .v-help-logo {
        max-width: 231px;
        margin-bottom: 26px; } }
    .v-help-logo--1 {
      margin-bottom: 40px; }
      @media (max-width: 1199px) {
        .v-help-logo--1 {
          margin-bottom: 26px; } }
  .v-help-props {
    position: relative;
    width: 600px;
    margin-top: 60px;
    max-width: 100%;
    margin-bottom: 8px; }
    @media (max-width: 1199px) {
      .v-help-props {
        margin-top: 0;
        max-width: none;
        width: auto; } }
    .v-help-props--1 {
      margin-top: 30px; }
      @media (max-width: 1199px) {
        .v-help-props--1 {
          margin-top: 0; } }
  .v-help-prop-title {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: #29235C; }
    @media (max-width: 1199px) {
      .v-help-prop-title {
        margin-bottom: 16px; } }
  .v-help-prop {
    position: relative;
    padding-left: 32px;
    background-image: url(../img/icon-li-agree--green.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #29235C;
    margin-bottom: 16px;
    min-height: 22px; }
    @media (max-width: 1199px) {
      .v-help-prop {
        font-weight: normal;
        font-size: 16px;
        line-height: 140%; } }

.val-btns {
  margin: 45px -8px 72px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767px) {
    .val-btns {
      margin: 25px -5px 25px; } }
  .val-btns > .button {
    margin: 0 8px 8px; }
    @media (max-width: 767px) {
      .val-btns > .button {
        margin: 0 5px 6px; } }

.footer {
  background: #00437E;
  color: #ffffff;
  padding: 48px 0;
  font-size: 18px;
  line-height: 155%; }
  @media (max-width: 1199px) {
    .footer {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%; } }
  .footer__logo {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    @media (max-width: 1199px) {
      .footer__logo {
        justify-content: center; } }
  .footer-menu {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .footer-menu__col {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: -24px; }
      @media (max-width: 1199px) {
        .footer-menu__col {
          flex: 0 0 50%;
          max-width: 50%; } }
      .footer-menu__col + .footer-menu__col {
        margin-left: 78px; }
        @media (max-width: 1199px) {
          .footer-menu__col + .footer-menu__col {
            margin-left: 0; } }
    .footer-menu a {
      color: #FFFFFF;
      display: inline-flex;
      margin-bottom: 24px; }
  .footer__border:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: #FFFFFF;
    opacity: 0.08;
    margin: 24px 0; }
  .footer__social {
    margin: 0 -8px -10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap; }
    @media (max-width: 1199px) {
      .footer__social {
        margin: 0 0 -10px; } }
    .footer__social > * {
      margin: 0 8px 10px; }
      @media (max-width: 1199px) {
        .footer__social > * {
          margin: 0 0 10px;
          flex: 0 0 100%;
          max-width: 100%; } }
  .footer__info {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 40px; }
    @media (max-width: 1199px) {
      .footer__info {
        font-weight: 500;
        font-size: 11px;
        line-height: 18px; } }
  .footer__copy {
    color: rgba(255, 255, 255, 0.5);
    max-width: 376px;
    font-size: 12px;
    line-height: 148.34%; }
    @media (max-width: 1199px) {
      .footer__copy {
        margin: auto; } }
  .footer__logo-fm svg {
    margin-left: auto; }
    @media (max-width: 1199px) {
      .footer__logo-fm svg {
        margin: auto; } }
  .footer__lc {
    letter-spacing: 0em; }
    @media (max-width: 1199px) {
      .footer__lc {
        font-size: 11px;
        line-height: 18px; } }
  .footer__feedback {
    margin-top: auto; }

.header-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .header-menu__item {
    font-weight: 600;
    font-size: 18px;
    line-height: 155%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-left: 95px;
    color: #29235C; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 87px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100; }
  @media (max-width: 1199px) {
    .header {
      height: 63px; } }
  .header__body {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .header__logo {
    margin-right: auto; }
    @media (max-width: 1199px) {
      .header__logo {
        max-width: 142px; } }

.homepage__heading {
  font-weight: 500;
  font-size: 69px;
  line-height: 1.16;
  color: #29235C;
  margin: 0 0 30px; }
  @media (max-width: 1199px) {
    .homepage__heading {
      font-size: 30px;
      line-height: 120%;
      text-align: center;
      margin: 0 0 12px; } }

.homepage__title {
  font-weight: 600;
  font-size: 35px;
  line-height: 135%;
  color: #29235C;
  margin: 0 0 8px; }
  @media (max-width: 1199px) {
    .homepage__title {
      font-size: 26px; } }

.homepage__desc {
  color: #29235C; }

.homepage__links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 70px -15px -8px; }
  @media (max-width: 1199px) {
    .homepage__links {
      margin: 30px -15px -8px;
      justify-content: center; } }
  .homepage__links > * {
    margin: 0 15px 8px; }

.homepage__caption {
  margin: 16px 0 0;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #8E8F8F;
  opacity: 0.8; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes bounce {
  0% {
    right: 0; }
  25% {
    right: -5px; }
  50% {
    right: 0; }
  75% {
    right: 5px; }
  100% {
    right: 0; } }

.loader {
  border-top: 0px solid rgba(71, 133, 4, 0.7);
  border-left: 0px solid rgba(71, 133, 4, 0.7);
  border-right: 4px solid rgba(71, 133, 4, 0.7);
  border-bottom: 4px solid rgba(71, 133, 4, 0.7);
  border-radius: 60px;
  width: 60px;
  height: 60px;
  animation: 1s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0s infinite spin;
  display: block;
  margin: auto; }

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 90;
  background: #BDE28F;
  text-align: center; }
  .mobile-menu__body {
    padding: 100px 0 50px;
    font-weight: 600;
    font-size: 35px;
    line-height: 1.35; }
    .mobile-menu__body > * {
      margin-bottom: 16px; }
  .mobile-menu__feedback {
    margin-top: 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 155%; }

.new {
  color: #29235C;
  padding: 0px 10px;
  background: #FFA841;
  border-radius: 20px;
  text-transform: uppercase; }

.pharmacological-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 0;
  background: #F8F8F8;
  font-family: 'Open Sans Condensed';
  font-weight: 300;
  font-size: 40px;
  line-height: 54px;
  text-transform: uppercase;
  color: #8E8F8F;
  text-align: center;
  z-index: 50; }
  @media (max-width: 1399px) {
    .pharmacological-warning {
      font-size: 20px;
      line-height: 24px; } }

.section-slider .owl-stage-outer {
  overflow: visible !important; }

.section-slider .news-card {
  width: 250px; }

.section {
  padding: 90px 0; }

.sky-board {
  background: linear-gradient(148.73deg, #83D0F6 17.92%, rgba(139, 210, 247, 0.38) 86.64%);
  padding: 0 0 40px; }
  @media (max-width: 1199px) {
    .sky-board {
      padding: 24px 0 40px; } }
  @media (max-width: 540px) {
    .sky-board {
      padding: 24px 0 0; } }
  .sky-board__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .sky-board__col {
    flex: 1 1 0; }
  .sky-board__image {
    flex: 0 0 45.90909%;
    max-width: 45.90909%; }
    @media (max-width: 1199px) {
      .sky-board__image {
        flex: 0 0 100%;
        max-width: 100%; } }
    .sky-board__image img {
      margin: auto; }
  .sky-board__test {
    margin: 55px 0 0; }
    @media (max-width: 1199px) {
      .sky-board__test {
        margin: 40px 0 0; } }

.test {
  text-align: center;
  color: #FFFFFF;
  background: #176BAD;
  border-radius: 30px;
  position: relative;
  overflow: hidden; }
  @media (max-width: 540px) {
    .test {
      margin: -15px; } }
  .test-body {
    padding: 48px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 1 0;
    position: relative; }
    @media (max-width: 1199px) {
      .test-body {
        padding: 48px 30px; } }
    .test-body__inner {
      position: relative;
      z-index: 1; }
    .test-body.hide {
      display: none; }
    .test-body--ending {
      text-align: left;
      max-width: 70%; }
      .test-body--ending_2 {
        max-width: 55%; }
      .test-body--ending_3 {
        max-width: 60%; }
      .test-body--ending_4 {
        max-width: 42%; }
  .test-back {
    display: block;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .test-back--ending {
      left: 70%;
      transform: translateY(-50%); }
      .test-back--ending_2 {
        left: 55%; }
      .test-back--ending_3 {
        top: 65%;
        left: 60%; }
      .test-back--ending_4 {
        left: 42%; }
  .test-heading {
    font-weight: 600;
    font-size: 26px;
    line-height: 135%;
    color: #FFFFFF;
    margin-bottom: 30px; }
    @media (max-width: 1199px) {
      .test-heading {
        margin-bottom: 20px; } }
    .test-heading--ending {
      opacity: 0.5;
      margin-bottom: 16px; }
  .test-first-title {
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    color: #FFFFFF;
    margin: 0 0 8px; }
    @media (max-width: 1199px) {
      .test-first-title {
        font-size: 35px;
        line-height: 130%; } }
  .test-header {
    margin: 0 auto 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column; }
    @media (max-width: 1199px) {
      .test-header {
        margin: 0 auto 38px; } }
    .test-header--ending {
      align-items: flex-start; }
  .test-answers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -8px -16px; }
    .test-answers > * {
      margin: 0 8px 16px; }
    .test-answers--ending {
      justify-content: flex-start; }
      .test-answers--ending_4 {
        font-size: 16px;
        position: absolute;
        left: 50%;
        top: 83%;
        transform: translateY(-50%);
        flex-wrap: nowrap; }
        .test-answers--ending_4 > * + * {
          margin-left: 30%; }
  .test-title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 35px;
    line-height: 135%; }
  .test-pagenav {
    padding: 4px 14px;
    background: #176BAD;
    border-radius: 36px;
    font-size: 22px;
    line-height: 135%; }
    .test-pagenav-page, .test-pagenav-del, .test-pagenav-pages {
      display: inline-block;
      vertical-align: middle; }

.video-desk {
  margin: 16px 0 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 155%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #000000;
  text-align: right; }
  @media (max-width: 1199px) {
    .video-desk {
      text-align: center;
      font-size: 14px;
      line-height: 140%; } }

.video-link {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 30px;
  transition: all 0.3s ease; }
  .video-link:hover {
    opacity: 0.8; }
  @media (max-width: 1199px) {
    .video-link {
      border-radius: 15px; } }
  .video-link img {
    margin: 0px auto; }
  .video-link svg {
    transform: translateY(15px); }
  .video-link__overlay {
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer; }
    .video-link__overlay > * {
      max-height: 100%;
      max-width: 100%; }

.video-wrapper {
  padding: 0 13px; }
  @media (max-width: 1199px) {
    .video-wrapper {
      margin-top: 60px; } }

.window {
  position: fixed;
  left: 50%;
  top: 50%;
  max-width: 100%;
  width: 800px;
  transform: translate(-50%, -50%);
  z-index: 2100; }
  @media (max-width: 1199px) {
    .window {
      top: 0px;
      transform: translate(-50%, 0); } }
  .window-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2000; }

.agree-window {
  background: #ffffff;
  border-radius: 16px;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  padding: 15px;
  text-align: center; }
  .agree-window .caption {
    font-size: 20px;
    margin-bottom: 15px; }
  .agree-window .agree_buttons {
    margin-top: 10px; }
    .agree-window .agree_buttons .agree_button, .agree-window .agree_buttons .agree_button_no {
      padding: 8px 30px;
      background: #699830;
      border-radius: 30px;
      color: #fff;
      text-transform: uppercase;
      border: 1px solid #699830;
      transition: opacity .2s;
      cursor: pointer; }
      .agree-window .agree_buttons .agree_button:hover, .agree-window .agree_buttons .agree_button_no:hover {
        opacity: .7; }
    .agree-window .agree_buttons .agree_button_no {
      background: #c43d47;
      border: 1px solid #c43d47; }

.img-fluid {
  max-width: 100%;
  display: block; }

.fluid {
  width: 100%;
  max-width: 100%; }

.clear {
  clear: both; }

.rotate-180 {
  transform: rotate(180deg); }

@media (min-width: 0px) {
  .jc-center {
    justify-content: center; }
  .important-jc-center {
    justify-content: center !important; }
  .jc-flex-start {
    justify-content: flex-start; }
  .important-jc-flex-start {
    justify-content: flex-start !important; }
  .jc-flex-end {
    justify-content: flex-end; }
  .important-jc-flex-end {
    justify-content: flex-end !important; }
  .jc-space-between {
    justify-content: space-between; }
  .important-jc-space-between {
    justify-content: space-between !important; }
  .ai-center {
    align-items: center; }
  .important-ai-center {
    align-items: center !important; }
  .ai-flex-start {
    align-items: flex-start; }
  .important-ai-flex-start {
    align-items: flex-start !important; }
  .ai-flex-end {
    align-items: flex-end; }
  .important-ai-flex-end {
    align-items: flex-end !important; }
  .ai-stretch {
    align-items: stretch; }
  .important-ai-stretch {
    align-items: stretch !important; }
  .as-center {
    align-self: center; }
  .important-as-center {
    align-self: center !important; }
  .as-flex-start {
    align-self: flex-start; }
  .important-as-flex-start {
    align-self: flex-start !important; }
  .as-flex-end {
    align-self: flex-end; }
  .important-as-flex-end {
    align-self: flex-end !important; }
  .as-stretch {
    align-self: stretch; }
  .important-as-stretch {
    align-self: stretch !important; }
  .text-center {
    text-align: center; }
  .important-text-center {
    text-align: center !important; }
  .text-left {
    text-align: left; }
  .important-text-left {
    text-align: left !important; }
  .text-right {
    text-align: right; }
  .important-text-right {
    text-align: right !important; }
  .d-block {
    display: block; }
  .important-d-block {
    display: block !important; }
  .d-inline-block {
    display: inline-block; }
  .important-d-inline-block {
    display: inline-block !important; }
  .d-flex {
    display: flex; }
  .important-d-flex {
    display: flex !important; }
  .d-inline-flex {
    display: inline-flex; }
  .important-d-inline-flex {
    display: inline-flex !important; }
  .d-inline {
    display: inline; }
  .important-d-inline {
    display: inline !important; }
  .d-none {
    display: none; }
  .important-d-none {
    display: none !important; }
  .order-0 {
    order: 0; }
  .important-order-0 {
    order: 0 !important; }
  .order-1 {
    order: 1; }
  .important-order-1 {
    order: 1 !important; }
  .order-2 {
    order: 2; }
  .important-order-2 {
    order: 2 !important; }
  .order-3 {
    order: 3; }
  .important-order-3 {
    order: 3 !important; }
  .order-4 {
    order: 4; }
  .important-order-4 {
    order: 4 !important; }
  .order-5 {
    order: 5; }
  .important-order-5 {
    order: 5 !important; }
  .order-6 {
    order: 6; }
  .important-order-6 {
    order: 6 !important; }
  .order-7 {
    order: 7; }
  .important-order-7 {
    order: 7 !important; }
  .order-8 {
    order: 8; }
  .important-order-8 {
    order: 8 !important; }
  .order-9 {
    order: 9; }
  .important-order-9 {
    order: 9 !important; }
  .white-space-nowrap {
    white-space: nowrap; }
  .important-white-space-nowrap {
    white-space: nowrap !important; }
  .white-space-pre {
    white-space: pre; }
  .important-white-space-pre {
    white-space: pre !important; }
  .fd-column {
    flex-direction: column; }
  .important-fd-column {
    flex-direction: column !important; }
  .fd-row {
    flex-direction: row; }
  .important-fd-row {
    flex-direction: row !important; }
  .flex-nowrap {
    flex-wrap: nowrap; }
  .important-flex-nowrap {
    flex-wrap: nowrap !important; }
  .flex-wrap {
    flex-wrap: wrap; }
  .important-flex-wrap {
    flex-wrap: wrap !important; }
  .overflow-hidden {
    overflow: hidden; }
  .important-overflow-hidden {
    overflow: hidden !important; }
  .overflow-visible {
    overflow: visible; }
  .important-overflow-visible {
    overflow: visible !important; }
  .overflow-scroll {
    overflow: scroll; }
  .important-overflow-scroll {
    overflow: scroll !important; }
  .overflow-auto {
    overflow: auto; }
  .important-overflow-auto {
    overflow: auto !important; }
  .m-0 {
    margin: 0; }
  .important-m-0 {
    margin: 0 !important; }
  .m-auto {
    margin: auto; }
  .important-m-auto {
    margin: auto !important; }
  .mb-0 {
    margin-bottom: 0; }
  .important-mb-0 {
    margin-bottom: 0 !important; }
  .mb-auto {
    margin-bottom: auto; }
  .important-mb-auto {
    margin-bottom: auto !important; }
  .mt-0 {
    margin-top: 0; }
  .important-mt-0 {
    margin-top: 0 !important; }
  .mt-auto {
    margin-top: auto; }
  .important-mt-auto {
    margin-top: auto !important; }
  .mr-0 {
    margin-right: 0; }
  .important-mr-0 {
    margin-right: 0 !important; }
  .mr-auto {
    margin-right: auto; }
  .important-mr-auto {
    margin-right: auto !important; }
  .ml-0 {
    margin-left: 0; }
  .important-ml-0 {
    margin-left: 0 !important; }
  .ml-auto {
    margin-left: auto; }
  .important-ml-auto {
    margin-left: auto !important; } }

@media (min-width: 576px) {
  .jc-sm-center {
    justify-content: center; }
  .important-jc-sm-center {
    justify-content: center !important; }
  .jc-sm-flex-start {
    justify-content: flex-start; }
  .important-jc-sm-flex-start {
    justify-content: flex-start !important; }
  .jc-sm-flex-end {
    justify-content: flex-end; }
  .important-jc-sm-flex-end {
    justify-content: flex-end !important; }
  .jc-sm-space-between {
    justify-content: space-between; }
  .important-jc-sm-space-between {
    justify-content: space-between !important; }
  .ai-sm-center {
    align-items: center; }
  .important-ai-sm-center {
    align-items: center !important; }
  .ai-sm-flex-start {
    align-items: flex-start; }
  .important-ai-sm-flex-start {
    align-items: flex-start !important; }
  .ai-sm-flex-end {
    align-items: flex-end; }
  .important-ai-sm-flex-end {
    align-items: flex-end !important; }
  .ai-sm-stretch {
    align-items: stretch; }
  .important-ai-sm-stretch {
    align-items: stretch !important; }
  .as-sm-center {
    align-self: center; }
  .important-as-sm-center {
    align-self: center !important; }
  .as-sm-flex-start {
    align-self: flex-start; }
  .important-as-sm-flex-start {
    align-self: flex-start !important; }
  .as-sm-flex-end {
    align-self: flex-end; }
  .important-as-sm-flex-end {
    align-self: flex-end !important; }
  .as-sm-stretch {
    align-self: stretch; }
  .important-as-sm-stretch {
    align-self: stretch !important; }
  .text-sm-center {
    text-align: center; }
  .important-text-sm-center {
    text-align: center !important; }
  .text-sm-left {
    text-align: left; }
  .important-text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right; }
  .important-text-sm-right {
    text-align: right !important; }
  .d-sm-block {
    display: block; }
  .important-d-sm-block {
    display: block !important; }
  .d-sm-inline-block {
    display: inline-block; }
  .important-d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-flex {
    display: flex; }
  .important-d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex; }
  .important-d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-inline {
    display: inline; }
  .important-d-sm-inline {
    display: inline !important; }
  .d-sm-none {
    display: none; }
  .important-d-sm-none {
    display: none !important; }
  .order-sm-0 {
    order: 0; }
  .important-order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1; }
  .important-order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2; }
  .important-order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3; }
  .important-order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4; }
  .important-order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5; }
  .important-order-sm-5 {
    order: 5 !important; }
  .order-sm-6 {
    order: 6; }
  .important-order-sm-6 {
    order: 6 !important; }
  .order-sm-7 {
    order: 7; }
  .important-order-sm-7 {
    order: 7 !important; }
  .order-sm-8 {
    order: 8; }
  .important-order-sm-8 {
    order: 8 !important; }
  .order-sm-9 {
    order: 9; }
  .important-order-sm-9 {
    order: 9 !important; }
  .white-space-sm-nowrap {
    white-space: nowrap; }
  .important-white-space-sm-nowrap {
    white-space: nowrap !important; }
  .white-space-sm-pre {
    white-space: pre; }
  .important-white-space-sm-pre {
    white-space: pre !important; }
  .fd-sm-column {
    flex-direction: column; }
  .important-fd-sm-column {
    flex-direction: column !important; }
  .fd-sm-row {
    flex-direction: row; }
  .important-fd-sm-row {
    flex-direction: row !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap; }
  .important-flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap {
    flex-wrap: wrap; }
  .important-flex-sm-wrap {
    flex-wrap: wrap !important; }
  .overflow-sm-hidden {
    overflow: hidden; }
  .important-overflow-sm-hidden {
    overflow: hidden !important; }
  .overflow-sm-visible {
    overflow: visible; }
  .important-overflow-sm-visible {
    overflow: visible !important; }
  .overflow-sm-scroll {
    overflow: scroll; }
  .important-overflow-sm-scroll {
    overflow: scroll !important; }
  .overflow-sm-auto {
    overflow: auto; }
  .important-overflow-sm-auto {
    overflow: auto !important; }
  .m-sm-0 {
    margin: 0; }
  .important-m-sm-0 {
    margin: 0 !important; }
  .m-sm-auto {
    margin: auto; }
  .important-m-sm-auto {
    margin: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0; }
  .important-mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-auto {
    margin-bottom: auto; }
  .important-mb-sm-auto {
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0; }
  .important-mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-auto {
    margin-top: auto; }
  .important-mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-0 {
    margin-right: 0; }
  .important-mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-auto {
    margin-right: auto; }
  .important-mr-sm-auto {
    margin-right: auto !important; }
  .ml-sm-0 {
    margin-left: 0; }
  .important-ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-auto {
    margin-left: auto; }
  .important-ml-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .jc-md-center {
    justify-content: center; }
  .important-jc-md-center {
    justify-content: center !important; }
  .jc-md-flex-start {
    justify-content: flex-start; }
  .important-jc-md-flex-start {
    justify-content: flex-start !important; }
  .jc-md-flex-end {
    justify-content: flex-end; }
  .important-jc-md-flex-end {
    justify-content: flex-end !important; }
  .jc-md-space-between {
    justify-content: space-between; }
  .important-jc-md-space-between {
    justify-content: space-between !important; }
  .ai-md-center {
    align-items: center; }
  .important-ai-md-center {
    align-items: center !important; }
  .ai-md-flex-start {
    align-items: flex-start; }
  .important-ai-md-flex-start {
    align-items: flex-start !important; }
  .ai-md-flex-end {
    align-items: flex-end; }
  .important-ai-md-flex-end {
    align-items: flex-end !important; }
  .ai-md-stretch {
    align-items: stretch; }
  .important-ai-md-stretch {
    align-items: stretch !important; }
  .as-md-center {
    align-self: center; }
  .important-as-md-center {
    align-self: center !important; }
  .as-md-flex-start {
    align-self: flex-start; }
  .important-as-md-flex-start {
    align-self: flex-start !important; }
  .as-md-flex-end {
    align-self: flex-end; }
  .important-as-md-flex-end {
    align-self: flex-end !important; }
  .as-md-stretch {
    align-self: stretch; }
  .important-as-md-stretch {
    align-self: stretch !important; }
  .text-md-center {
    text-align: center; }
  .important-text-md-center {
    text-align: center !important; }
  .text-md-left {
    text-align: left; }
  .important-text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right; }
  .important-text-md-right {
    text-align: right !important; }
  .d-md-block {
    display: block; }
  .important-d-md-block {
    display: block !important; }
  .d-md-inline-block {
    display: inline-block; }
  .important-d-md-inline-block {
    display: inline-block !important; }
  .d-md-flex {
    display: flex; }
  .important-d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex; }
  .important-d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-inline {
    display: inline; }
  .important-d-md-inline {
    display: inline !important; }
  .d-md-none {
    display: none; }
  .important-d-md-none {
    display: none !important; }
  .order-md-0 {
    order: 0; }
  .important-order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1; }
  .important-order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2; }
  .important-order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3; }
  .important-order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4; }
  .important-order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5; }
  .important-order-md-5 {
    order: 5 !important; }
  .order-md-6 {
    order: 6; }
  .important-order-md-6 {
    order: 6 !important; }
  .order-md-7 {
    order: 7; }
  .important-order-md-7 {
    order: 7 !important; }
  .order-md-8 {
    order: 8; }
  .important-order-md-8 {
    order: 8 !important; }
  .order-md-9 {
    order: 9; }
  .important-order-md-9 {
    order: 9 !important; }
  .white-space-md-nowrap {
    white-space: nowrap; }
  .important-white-space-md-nowrap {
    white-space: nowrap !important; }
  .white-space-md-pre {
    white-space: pre; }
  .important-white-space-md-pre {
    white-space: pre !important; }
  .fd-md-column {
    flex-direction: column; }
  .important-fd-md-column {
    flex-direction: column !important; }
  .fd-md-row {
    flex-direction: row; }
  .important-fd-md-row {
    flex-direction: row !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap; }
  .important-flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap {
    flex-wrap: wrap; }
  .important-flex-md-wrap {
    flex-wrap: wrap !important; }
  .overflow-md-hidden {
    overflow: hidden; }
  .important-overflow-md-hidden {
    overflow: hidden !important; }
  .overflow-md-visible {
    overflow: visible; }
  .important-overflow-md-visible {
    overflow: visible !important; }
  .overflow-md-scroll {
    overflow: scroll; }
  .important-overflow-md-scroll {
    overflow: scroll !important; }
  .overflow-md-auto {
    overflow: auto; }
  .important-overflow-md-auto {
    overflow: auto !important; }
  .m-md-0 {
    margin: 0; }
  .important-m-md-0 {
    margin: 0 !important; }
  .m-md-auto {
    margin: auto; }
  .important-m-md-auto {
    margin: auto !important; }
  .mb-md-0 {
    margin-bottom: 0; }
  .important-mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-auto {
    margin-bottom: auto; }
  .important-mb-md-auto {
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0; }
  .important-mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-auto {
    margin-top: auto; }
  .important-mt-md-auto {
    margin-top: auto !important; }
  .mr-md-0 {
    margin-right: 0; }
  .important-mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-auto {
    margin-right: auto; }
  .important-mr-md-auto {
    margin-right: auto !important; }
  .ml-md-0 {
    margin-left: 0; }
  .important-ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-auto {
    margin-left: auto; }
  .important-ml-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .jc-lg-center {
    justify-content: center; }
  .important-jc-lg-center {
    justify-content: center !important; }
  .jc-lg-flex-start {
    justify-content: flex-start; }
  .important-jc-lg-flex-start {
    justify-content: flex-start !important; }
  .jc-lg-flex-end {
    justify-content: flex-end; }
  .important-jc-lg-flex-end {
    justify-content: flex-end !important; }
  .jc-lg-space-between {
    justify-content: space-between; }
  .important-jc-lg-space-between {
    justify-content: space-between !important; }
  .ai-lg-center {
    align-items: center; }
  .important-ai-lg-center {
    align-items: center !important; }
  .ai-lg-flex-start {
    align-items: flex-start; }
  .important-ai-lg-flex-start {
    align-items: flex-start !important; }
  .ai-lg-flex-end {
    align-items: flex-end; }
  .important-ai-lg-flex-end {
    align-items: flex-end !important; }
  .ai-lg-stretch {
    align-items: stretch; }
  .important-ai-lg-stretch {
    align-items: stretch !important; }
  .as-lg-center {
    align-self: center; }
  .important-as-lg-center {
    align-self: center !important; }
  .as-lg-flex-start {
    align-self: flex-start; }
  .important-as-lg-flex-start {
    align-self: flex-start !important; }
  .as-lg-flex-end {
    align-self: flex-end; }
  .important-as-lg-flex-end {
    align-self: flex-end !important; }
  .as-lg-stretch {
    align-self: stretch; }
  .important-as-lg-stretch {
    align-self: stretch !important; }
  .text-lg-center {
    text-align: center; }
  .important-text-lg-center {
    text-align: center !important; }
  .text-lg-left {
    text-align: left; }
  .important-text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right; }
  .important-text-lg-right {
    text-align: right !important; }
  .d-lg-block {
    display: block; }
  .important-d-lg-block {
    display: block !important; }
  .d-lg-inline-block {
    display: inline-block; }
  .important-d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-flex {
    display: flex; }
  .important-d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex; }
  .important-d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-inline {
    display: inline; }
  .important-d-lg-inline {
    display: inline !important; }
  .d-lg-none {
    display: none; }
  .important-d-lg-none {
    display: none !important; }
  .order-lg-0 {
    order: 0; }
  .important-order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1; }
  .important-order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2; }
  .important-order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3; }
  .important-order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4; }
  .important-order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5; }
  .important-order-lg-5 {
    order: 5 !important; }
  .order-lg-6 {
    order: 6; }
  .important-order-lg-6 {
    order: 6 !important; }
  .order-lg-7 {
    order: 7; }
  .important-order-lg-7 {
    order: 7 !important; }
  .order-lg-8 {
    order: 8; }
  .important-order-lg-8 {
    order: 8 !important; }
  .order-lg-9 {
    order: 9; }
  .important-order-lg-9 {
    order: 9 !important; }
  .white-space-lg-nowrap {
    white-space: nowrap; }
  .important-white-space-lg-nowrap {
    white-space: nowrap !important; }
  .white-space-lg-pre {
    white-space: pre; }
  .important-white-space-lg-pre {
    white-space: pre !important; }
  .fd-lg-column {
    flex-direction: column; }
  .important-fd-lg-column {
    flex-direction: column !important; }
  .fd-lg-row {
    flex-direction: row; }
  .important-fd-lg-row {
    flex-direction: row !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap; }
  .important-flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap {
    flex-wrap: wrap; }
  .important-flex-lg-wrap {
    flex-wrap: wrap !important; }
  .overflow-lg-hidden {
    overflow: hidden; }
  .important-overflow-lg-hidden {
    overflow: hidden !important; }
  .overflow-lg-visible {
    overflow: visible; }
  .important-overflow-lg-visible {
    overflow: visible !important; }
  .overflow-lg-scroll {
    overflow: scroll; }
  .important-overflow-lg-scroll {
    overflow: scroll !important; }
  .overflow-lg-auto {
    overflow: auto; }
  .important-overflow-lg-auto {
    overflow: auto !important; }
  .m-lg-0 {
    margin: 0; }
  .important-m-lg-0 {
    margin: 0 !important; }
  .m-lg-auto {
    margin: auto; }
  .important-m-lg-auto {
    margin: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0; }
  .important-mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-auto {
    margin-bottom: auto; }
  .important-mb-lg-auto {
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0; }
  .important-mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-auto {
    margin-top: auto; }
  .important-mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-0 {
    margin-right: 0; }
  .important-mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-auto {
    margin-right: auto; }
  .important-mr-lg-auto {
    margin-right: auto !important; }
  .ml-lg-0 {
    margin-left: 0; }
  .important-ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-auto {
    margin-left: auto; }
  .important-ml-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .jc-xl-center {
    justify-content: center; }
  .important-jc-xl-center {
    justify-content: center !important; }
  .jc-xl-flex-start {
    justify-content: flex-start; }
  .important-jc-xl-flex-start {
    justify-content: flex-start !important; }
  .jc-xl-flex-end {
    justify-content: flex-end; }
  .important-jc-xl-flex-end {
    justify-content: flex-end !important; }
  .jc-xl-space-between {
    justify-content: space-between; }
  .important-jc-xl-space-between {
    justify-content: space-between !important; }
  .ai-xl-center {
    align-items: center; }
  .important-ai-xl-center {
    align-items: center !important; }
  .ai-xl-flex-start {
    align-items: flex-start; }
  .important-ai-xl-flex-start {
    align-items: flex-start !important; }
  .ai-xl-flex-end {
    align-items: flex-end; }
  .important-ai-xl-flex-end {
    align-items: flex-end !important; }
  .ai-xl-stretch {
    align-items: stretch; }
  .important-ai-xl-stretch {
    align-items: stretch !important; }
  .as-xl-center {
    align-self: center; }
  .important-as-xl-center {
    align-self: center !important; }
  .as-xl-flex-start {
    align-self: flex-start; }
  .important-as-xl-flex-start {
    align-self: flex-start !important; }
  .as-xl-flex-end {
    align-self: flex-end; }
  .important-as-xl-flex-end {
    align-self: flex-end !important; }
  .as-xl-stretch {
    align-self: stretch; }
  .important-as-xl-stretch {
    align-self: stretch !important; }
  .text-xl-center {
    text-align: center; }
  .important-text-xl-center {
    text-align: center !important; }
  .text-xl-left {
    text-align: left; }
  .important-text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right; }
  .important-text-xl-right {
    text-align: right !important; }
  .d-xl-block {
    display: block; }
  .important-d-xl-block {
    display: block !important; }
  .d-xl-inline-block {
    display: inline-block; }
  .important-d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-flex {
    display: flex; }
  .important-d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex; }
  .important-d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-inline {
    display: inline; }
  .important-d-xl-inline {
    display: inline !important; }
  .d-xl-none {
    display: none; }
  .important-d-xl-none {
    display: none !important; }
  .order-xl-0 {
    order: 0; }
  .important-order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1; }
  .important-order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2; }
  .important-order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3; }
  .important-order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4; }
  .important-order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5; }
  .important-order-xl-5 {
    order: 5 !important; }
  .order-xl-6 {
    order: 6; }
  .important-order-xl-6 {
    order: 6 !important; }
  .order-xl-7 {
    order: 7; }
  .important-order-xl-7 {
    order: 7 !important; }
  .order-xl-8 {
    order: 8; }
  .important-order-xl-8 {
    order: 8 !important; }
  .order-xl-9 {
    order: 9; }
  .important-order-xl-9 {
    order: 9 !important; }
  .white-space-xl-nowrap {
    white-space: nowrap; }
  .important-white-space-xl-nowrap {
    white-space: nowrap !important; }
  .white-space-xl-pre {
    white-space: pre; }
  .important-white-space-xl-pre {
    white-space: pre !important; }
  .fd-xl-column {
    flex-direction: column; }
  .important-fd-xl-column {
    flex-direction: column !important; }
  .fd-xl-row {
    flex-direction: row; }
  .important-fd-xl-row {
    flex-direction: row !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap; }
  .important-flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap {
    flex-wrap: wrap; }
  .important-flex-xl-wrap {
    flex-wrap: wrap !important; }
  .overflow-xl-hidden {
    overflow: hidden; }
  .important-overflow-xl-hidden {
    overflow: hidden !important; }
  .overflow-xl-visible {
    overflow: visible; }
  .important-overflow-xl-visible {
    overflow: visible !important; }
  .overflow-xl-scroll {
    overflow: scroll; }
  .important-overflow-xl-scroll {
    overflow: scroll !important; }
  .overflow-xl-auto {
    overflow: auto; }
  .important-overflow-xl-auto {
    overflow: auto !important; }
  .m-xl-0 {
    margin: 0; }
  .important-m-xl-0 {
    margin: 0 !important; }
  .m-xl-auto {
    margin: auto; }
  .important-m-xl-auto {
    margin: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0; }
  .important-mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-auto {
    margin-bottom: auto; }
  .important-mb-xl-auto {
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0; }
  .important-mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-auto {
    margin-top: auto; }
  .important-mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-0 {
    margin-right: 0; }
  .important-mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-auto {
    margin-right: auto; }
  .important-mr-xl-auto {
    margin-right: auto !important; }
  .ml-xl-0 {
    margin-left: 0; }
  .important-ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-auto {
    margin-left: auto; }
  .important-ml-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .jc-xxl-center {
    justify-content: center; }
  .important-jc-xxl-center {
    justify-content: center !important; }
  .jc-xxl-flex-start {
    justify-content: flex-start; }
  .important-jc-xxl-flex-start {
    justify-content: flex-start !important; }
  .jc-xxl-flex-end {
    justify-content: flex-end; }
  .important-jc-xxl-flex-end {
    justify-content: flex-end !important; }
  .jc-xxl-space-between {
    justify-content: space-between; }
  .important-jc-xxl-space-between {
    justify-content: space-between !important; }
  .ai-xxl-center {
    align-items: center; }
  .important-ai-xxl-center {
    align-items: center !important; }
  .ai-xxl-flex-start {
    align-items: flex-start; }
  .important-ai-xxl-flex-start {
    align-items: flex-start !important; }
  .ai-xxl-flex-end {
    align-items: flex-end; }
  .important-ai-xxl-flex-end {
    align-items: flex-end !important; }
  .ai-xxl-stretch {
    align-items: stretch; }
  .important-ai-xxl-stretch {
    align-items: stretch !important; }
  .as-xxl-center {
    align-self: center; }
  .important-as-xxl-center {
    align-self: center !important; }
  .as-xxl-flex-start {
    align-self: flex-start; }
  .important-as-xxl-flex-start {
    align-self: flex-start !important; }
  .as-xxl-flex-end {
    align-self: flex-end; }
  .important-as-xxl-flex-end {
    align-self: flex-end !important; }
  .as-xxl-stretch {
    align-self: stretch; }
  .important-as-xxl-stretch {
    align-self: stretch !important; }
  .text-xxl-center {
    text-align: center; }
  .important-text-xxl-center {
    text-align: center !important; }
  .text-xxl-left {
    text-align: left; }
  .important-text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right; }
  .important-text-xxl-right {
    text-align: right !important; }
  .d-xxl-block {
    display: block; }
  .important-d-xxl-block {
    display: block !important; }
  .d-xxl-inline-block {
    display: inline-block; }
  .important-d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-flex {
    display: flex; }
  .important-d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex; }
  .important-d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-inline {
    display: inline; }
  .important-d-xxl-inline {
    display: inline !important; }
  .d-xxl-none {
    display: none; }
  .important-d-xxl-none {
    display: none !important; }
  .order-xxl-0 {
    order: 0; }
  .important-order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1; }
  .important-order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2; }
  .important-order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3; }
  .important-order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4; }
  .important-order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5; }
  .important-order-xxl-5 {
    order: 5 !important; }
  .order-xxl-6 {
    order: 6; }
  .important-order-xxl-6 {
    order: 6 !important; }
  .order-xxl-7 {
    order: 7; }
  .important-order-xxl-7 {
    order: 7 !important; }
  .order-xxl-8 {
    order: 8; }
  .important-order-xxl-8 {
    order: 8 !important; }
  .order-xxl-9 {
    order: 9; }
  .important-order-xxl-9 {
    order: 9 !important; }
  .white-space-xxl-nowrap {
    white-space: nowrap; }
  .important-white-space-xxl-nowrap {
    white-space: nowrap !important; }
  .white-space-xxl-pre {
    white-space: pre; }
  .important-white-space-xxl-pre {
    white-space: pre !important; }
  .fd-xxl-column {
    flex-direction: column; }
  .important-fd-xxl-column {
    flex-direction: column !important; }
  .fd-xxl-row {
    flex-direction: row; }
  .important-fd-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap; }
  .important-flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap; }
  .important-flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .overflow-xxl-hidden {
    overflow: hidden; }
  .important-overflow-xxl-hidden {
    overflow: hidden !important; }
  .overflow-xxl-visible {
    overflow: visible; }
  .important-overflow-xxl-visible {
    overflow: visible !important; }
  .overflow-xxl-scroll {
    overflow: scroll; }
  .important-overflow-xxl-scroll {
    overflow: scroll !important; }
  .overflow-xxl-auto {
    overflow: auto; }
  .important-overflow-xxl-auto {
    overflow: auto !important; }
  .m-xxl-0 {
    margin: 0; }
  .important-m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-auto {
    margin: auto; }
  .important-m-xxl-auto {
    margin: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0; }
  .important-mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-auto {
    margin-bottom: auto; }
  .important-mb-xxl-auto {
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0; }
  .important-mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-auto {
    margin-top: auto; }
  .important-mt-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-0 {
    margin-right: 0; }
  .important-mr-xxl-0 {
    margin-right: 0 !important; }
  .mr-xxl-auto {
    margin-right: auto; }
  .important-mr-xxl-auto {
    margin-right: auto !important; }
  .ml-xxl-0 {
    margin-left: 0; }
  .important-ml-xxl-0 {
    margin-left: 0 !important; }
  .ml-xxl-auto {
    margin-left: auto; }
  .important-ml-xxl-auto {
    margin-left: auto !important; } }

.article-head {
  margin: 0 0 56px; }
  @media (max-width: 767px) {
    .article-head {
      margin: 0 0 38px; } }

.article-title {
  text-align: center;
  font-weight: 500;
  font-size: 49px;
  line-height: 52px;
  color: #1D1E1F;
  margin: 0 0 15px; }
  @media (max-width: 767px) {
    .article-title {
      font-size: 22px;
      line-height: 135%; } }
  .article-title--left {
    text-align: left; }

.article-authors {
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;
  text-align: center;
  color: #1D1E1F;
  opacity: 0.5; }
  @media (max-width: 767px) {
    .article-authors {
      font-size: 16px; } }

.article-prev-picture {
  border-radius: 30px;
  background: center / cover no-repeat #a6a6a5;
  display: block;
  text-decoration: none;
  height: 421px;
  width: 100%;
  margin: 0 0 40px; }
  @media (max-width: 767px) {
    .article-prev-picture {
      height: 260px; } }

.article-preview {
  font-weight: 600;
  font-size: 26px;
  color: #2ABAEC;
  padding-bottom: 32px;
  position: relative;
  margin: 0 0 30px;
  display: block; }
  @media (max-width: 1199px) {
    .article-preview {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%; } }
  .article-preview:after {
    content: '';
    display: block;
    width: 82.0122%;
    height: 1px;
    background: #29235C;
    opacity: 0.5;
    position: absolute;
    left: 0;
    bottom: 0; }

.article blockquote, .article h2, .article h3 {
  font-weight: 600;
  font-size: 35px;
  line-height: 1.35;
  color: #29235C;
  margin-bottom: 16px; }
  @media (max-width: 767px) {
    .article blockquote, .article h2, .article h3 {
      font-size: 22px;
      margin-bottom: 8px; } }

.article * + h2, .article * + h3, .article * + h4 {
  margin-top: 24px; }
  @media (max-width: 767px) {
    .article * + h2, .article * + h3, .article * + h4 {
      margin-top: 16px; } }

.article h3 {
  font-size: 28px; }
  @media (max-width: 767px) {
    .article h3 {
      font-size: 20px; } }

.article h4 {
  color: #29235C;
  margin-bottom: 8px;
  font-size: inherit;
  font-weight: bold; }

.article img {
  margin: 40px auto; }

.article p {
  margin-bottom: 1em; }

.article ol {
  list-style: decimal; }

.article ul {
  list-style: disc; }

.article i {
  font-style: italic; }

.article b, .article strong {
  font-weight: bold; }

.article ul, .article ol {
  padding-left: 30px;
  margin: 0 0 1em; }

.article .table-responsive {
  overflow: auto;
  width: 100%;
  max-width: 100%; }

.article table {
  font-size: 0.8em;
  margin: 1em auto; }
  .article table th, .article table tr, .article table td {
    border: 2px solid #e3e3e3;
    padding: 3px 5px; }
  .article table th {
    font-weight: bold; }

.article blockquote {
  padding: 32px 0 35px;
  margin-bottom: 40px;
  position: relative;
  z-index: 1; }
  .article blockquote:before {
    content: '';
    display: block;
    width: 1000vw;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    background: #F8F8F8;
    transform: translateX(-50%);
    z-index: -1; }

.banner-drug-2 {
  display: block;
  align-content: center;
  padding: 0px 40px;
  position: relative; }
  @media (max-width: 768px) {
    .banner-drug-2 {
      padding: 0px 20px; } }
  .banner-drug-2--rounded {
    border-radius: 30px;
    padding-top: 85px;
    padding-bottom: 40px;
    min-height: 436px; }
    @media (max-width: 767px) {
      .banner-drug-2--rounded {
        border-radius: 25px;
        text-align: center;
        margin-left: -15px;
        margin-right: -15px;
        display: block;
        width: auto; } }
  .banner-drug-2--val {
    background-color: #A4D369; }
  .banner-drug-2--val-plus {
    background-color: #7FCEF6; }
  .banner-drug-2--gray {
    background: #F8F8F8; }
  .banner-drug-2__labels {
    margin: 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .banner-drug-2__title {
    margin-top: 110px;
    margin-bottom: 23px;
    font-style: normal;
    font-weight: 500;
    font-size: 69px;
    line-height: 70px;
    color: #29235C; }
    @media (max-width: 1200px) {
      .banner-drug-2__title {
        font-size: 51px;
        line-height: 58px;
        margin-top: 50px; } }
    @media (max-width: 992px) {
      .banner-drug-2__title {
        font-size: 43px; } }
    @media (max-width: 768px) {
      .banner-drug-2__title {
        margin-top: 24px;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 35px;
        line-height: 130%; } }
  .banner-drug-2__slogan {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 60px;
    text-decoration: none;
    display: block; }
    @media (max-width: 1200px) {
      .banner-drug-2__slogan {
        font-size: 25px; } }
    @media (max-width: 992px) {
      .banner-drug-2__slogan {
        font-size: 21px;
        margin-bottom: 40px; } }
    @media (max-width: 768px) {
      .banner-drug-2__slogan {
        font-size: 22px;
        line-height: 135%;
        margin-bottom: 0px; } }
    @media (max-width: 380px) {
      .banner-drug-2__slogan {
        font-size: 20px; } }
    .banner-drug-2__slogan--sm-m {
      margin-bottom: 16px; }
      @media (max-width: 767px) {
        .banner-drug-2__slogan--sm-m {
          margin-bottom: 16px; } }
  .banner-drug-2__author {
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
    color: #8E8F8F; }
  .banner-drug-2__link {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 18px;
    line-height: 155%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #2ABAEC;
    text-decoration: none; }
    .banner-drug-2__link svg {
      margin-left: 8px;
      display: block; }
  @media (max-width: 768px) {
    .banner-drug-2--rounded .banner-drug-2__slogan {
      margin-bottom: 25px; } }
  @media (max-width: 767px) {
    .banner-drug-2--rounded .banner-drug-2__slogan--sm-m {
      margin-bottom: 16px; } }
  .banner-drug-2__properties {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 92px;
    max-width: 550px; }
    @media (max-width: 1200px) {
      .banner-drug-2__properties {
        font-size: 18px; } }
    @media (max-width: 992px) {
      .banner-drug-2__properties {
        font-size: 16px; } }
    @media (max-width: 768px) {
      .banner-drug-2__properties {
        font-size: 18px;
        line-height: 140%;
        margin-top: 15px;
        margin-bottom: 32px; } }
    .banner-drug-2__properties__itm {
      padding-left: 32px;
      background-image: url(../img/icon-li-agree.svg);
      background-repeat: no-repeat;
      background-position: 0px 4px; }
  .banner-drug-2__buy-btn {
    margin-bottom: 115px; }
    @media (max-width: 1200px) {
      .banner-drug-2__buy-btn {
        margin-bottom: 75px; } }
    @media (max-width: 768px) {
      .banner-drug-2__buy-btn {
        text-align: center;
        margin-bottom: 48px; } }
  .banner-drug-2__img--absolute {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }
    @media (max-width: 767px) {
      .banner-drug-2__img--absolute {
        height: auto;
        position: relative; } }
    .banner-drug-2__img--absolute img {
      display: block;
      height: 100%;
      margin-left: auto; }
      @media (max-width: 767px) {
        .banner-drug-2__img--absolute img {
          height: auto;
          margin-right: auto; } }

.banner-drug {
  padding: 0px 40px; }
  @media (max-width: 768px) {
    .banner-drug {
      padding: 0px 20px; } }
  .banner-drug--val {
    background-color: #A4D369; }
  .banner-drug--val-plus {
    background-color: #7FCEF6; }
  .banner-drug__title {
    margin-top: 110px;
    font-style: normal;
    font-weight: 500;
    font-size: 69px;
    line-height: 70px;
    color: #29235C; }
    .banner-drug__title__description {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      color: #29235C;
      opacity: 0.5;
      margin-bottom: 23px; }
    @media (max-width: 1200px) {
      .banner-drug__title {
        font-size: 51px;
        line-height: 58px;
        margin-top: 50px; } }
    @media (max-width: 992px) {
      .banner-drug__title {
        font-size: 43px; } }
    @media (max-width: 768px) {
      .banner-drug__title {
        margin-top: 24px;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 35px;
        line-height: 130%; } }
  .banner-drug__slogan {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 60px; }
    @media (max-width: 1200px) {
      .banner-drug__slogan {
        font-size: 25px; } }
    @media (max-width: 992px) {
      .banner-drug__slogan {
        font-size: 21px;
        margin-bottom: 40px; } }
    @media (max-width: 768px) {
      .banner-drug__slogan {
        font-size: 22px;
        line-height: 135%;
        margin-bottom: 0px; } }
    @media (max-width: 380px) {
      .banner-drug__slogan {
        font-size: 20px; } }
  .banner-drug__properties {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 92px;
    max-width: 550px; }
    @media (max-width: 1200px) {
      .banner-drug__properties {
        font-size: 18px; } }
    @media (max-width: 992px) {
      .banner-drug__properties {
        font-size: 16px; } }
    @media (max-width: 768px) {
      .banner-drug__properties {
        font-size: 18px;
        line-height: 140%;
        margin-top: 15px;
        margin-bottom: 32px; } }
    .banner-drug__properties__itm {
      padding-left: 32px;
      background-image: url(../img/icon-li-agree.svg);
      background-repeat: no-repeat;
      background-position: 0px 4px; }
      .banner-drug__properties__itm--valemidin {
        background-image: url(../img/icon-li-agree--green.svg); }
  .banner-drug__buy-btn {
    margin-bottom: 115px; }
    @media (max-width: 1200px) {
      .banner-drug__buy-btn {
        margin-bottom: 75px; } }
    @media (max-width: 768px) {
      .banner-drug__buy-btn {
        text-align: center;
        margin-bottom: 48px; } }
    .banner-drug__buy-btn__description {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #1D1E1F;
      opacity: 0.8; }

.breadcrumbs {
  display: flex;
  margin-top: 72px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .breadcrumbs {
      margin-top: 16px;
      margin-bottom: 42px; } }
  .breadcrumbs__icon {
    align-self: center;
    margin-right: 13px; }
    @media (max-width: 767px) {
      .breadcrumbs__icon {
        margin-right: 10px; } }
    @media (max-width: 767px) {
      .breadcrumbs__icon svg {
        width: 4px;
        height: 8px; } }
  .breadcrumbs__separator {
    width: 4px;
    height: 4px;
    background: #1D1E1F;
    align-self: center;
    margin: 0px 13px; }
    @media (max-width: 767px) {
      .breadcrumbs__separator {
        display: none; } }
  .breadcrumbs a {
    font-weight: 500;
    color: #1D1E1F;
    opacity: 0.5;
    transition: 0.3s ease all; }
    .breadcrumbs a:hover {
      opacity: 0.8; }
  .breadcrumbs__itm {
    margin: 0px 13px;
    font-size: 22px;
    line-height: 135%; }
    @media (max-width: 1199px) {
      .breadcrumbs__itm {
        font-size: 18px;
        line-height: 140%;
        margin: 0px 8px; } }
    @media (max-width: 767px) {
      .breadcrumbs__itm {
        font-size: 14px;
        line-height: 140%;
        margin: 0px 6px; } }
    .breadcrumbs__itm:first-child {
      margin-left: 0px; }
    .breadcrumbs__itm--last {
      opacity: 1; }
      @media (max-width: 767px) {
        .breadcrumbs__itm--last {
          display: none; } }
      .breadcrumbs__itm--last:hover {
        opacity: 1; }

.breadcrumbs2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 72px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .breadcrumbs2 {
      margin-top: 16px;
      margin-bottom: 32px; } }
  .breadcrumbs2-item {
    margin-right: 12px;
    flex: 0 0 auto;
    max-width: 100%;
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    opacity: 0.5;
    transition: opacity .2s; }
    .breadcrumbs2-item:hover {
      opacity: .7; }
    .breadcrumbs2-item:not(a):hover {
      opacity: 1; }
    .breadcrumbs2-item:not(a) {
      opacity: 1; }
    @media (max-width: 767px) {
      .breadcrumbs2-item {
        font-size: 18px;
        line-height: 140%; } }
    .breadcrumbs2-item svg {
      margin-right: 12px; }
      @media (max-width: 767px) {
        .breadcrumbs2-item svg {
          width: 4px;
          margin-right: 8px; } }

.button {
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.04em;
  transition: 0.3s ease all;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 17px;
  line-height: 1.4;
  border-radius: 50px;
  text-transform: none;
  color: #1D1E1F;
  border: 1px solid #1D1E1F; }
  .button:hover:not(.active) {
    opacity: .7; }
  .button.active {
    background: #478504;
    color: #FFFFFF;
    border-color: #478504; }

.faq {
  padding: 80px 40px; }
  @media (max-width: 768px) {
    .faq {
      padding: 40px 15px 72px; } }
  .faq__title {
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    color: #29235C; }
    @media (max-width: 1200px) {
      .faq__title {
        font-size: 44px;
        line-height: 120%; } }
    @media (max-width: 992px) {
      .faq__title {
        font-size: 40px;
        line-height: 120%; } }
    @media (max-width: 768px) {
      .faq__title {
        font-weight: 600;
        font-size: 35px;
        line-height: 130%;
        margin-bottom: 24px; } }
  .faq__list li.q {
    background-color: #F8F8F8;
    border-radius: 30px;
    padding: 27px 62px 27px 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    cursor: pointer;
    margin-bottom: 8px;
    transition: 0.3s ease all;
    position: relative; }
    @media (max-width: 992px) {
      .faq__list li.q {
        padding: 21px 56px 21px 28px;
        font-size: 20px; } }
    @media (max-width: 768px) {
      .faq__list li.q {
        padding: 16px 56px 16px 21px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: #1D1E1F;
        margin-bottom: 12px; } }
    .faq__list li.q span {
      position: absolute;
      right: 27px;
      top: 50%;
      margin-top: -11px;
      width: 22px;
      height: 22px;
      display: block;
      background-image: url("../img/icon-plus.svg");
      background-repeat: no-repeat;
      transition: 0.3s ease all; }
    .faq__list li.q--active span {
      transform: rotate(45deg); }
  .faq__list li.a {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
    padding: 24px 40px;
    color: #1D1E1F;
    display: none; }
    @media (max-width: 768px) {
      .faq__list li.a {
        padding: 5px 30px 15px 30px;
        font-size: 16px;
        line-height: 130%; } }
  .faq__more {
    display: flex;
    justify-content: flex-end;
    margin-top: 45px; }
    @media (max-width: 768px) {
      .faq__more {
        margin-top: 25px;
        justify-content: center; } }
    .faq__more a {
      display: flex;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #29235C;
      align-items: center;
      text-decoration: none;
      transition: 0.3s ease all; }
      .faq__more a svg {
        margin-left: 16px;
        transition: 0.3s ease all; }
      .faq__more a:hover {
        transition: 0.3s ease all; }
        .faq__more a:hover svg {
          transition: 0.3s ease all;
          transform: translateX(-4px); }
      @media (max-width: 768px) {
        .faq__more a {
          font-size: 18px;
          line-height: 125%; } }
  .faq__page {
    padding: 20px 0px 80px; }
    .faq__page__itm {
      padding: 24px 0px;
      border-bottom: 1px solid #eae9ef; }
      .faq__page__itm__q {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 135%;
        color: #29235C;
        margin-bottom: 8px; }
        @media (max-width: 1399px) {
          .faq__page__itm__q {
            font-size: 25px; } }
        @media (max-width: 1199px) {
          .faq__page__itm__q {
            font-size: 24px; } }
        @media (max-width: 1199px) {
          .faq__page__itm__q {
            font-size: 22px; } }
      .faq__page__itm__a {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 155%;
        color: #1D1E1F; }
        @media (max-width: 1199px) {
          .faq__page__itm__a {
            font-size: 16px;
            line-height: 140%; } }

.help-block {
  position: relative; }
  .help-block--val {
    background-color: #f4e8f7;
    background-image: url("../img/logo-default-flowers.svg");
    background-repeat: no-repeat;
    background-position: 95% 10%; }
    @media (max-width: 768px) {
      .help-block--val {
        background-position: 50% 10%; } }
  .help-block__img {
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    max-width: 70%; }
    .help-block__img--val {
      background: #c88dd7; }
    @media (max-width: 768px) {
      .help-block__img {
        width: 100%;
        max-width: 100%;
        clip-path: polygon(0% 59%, 0% 100%, 100% 100%); } }
  .help-block__text {
    padding: 40px 42px 45px;
    position: absolute;
    right: 30%;
    bottom: 150px;
    border-radius: 25px;
    max-width: 590px;
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 52px; }
    @media (max-width: 2529px) {
      .help-block__text {
        right: 10%; } }
    @media (max-width: 1600px) {
      .help-block__text {
        font-size: 44px;
        line-height: 130%;
        right: 5%;
        padding: 30px 32px 35px; } }
    @media (max-width: 1200px) {
      .help-block__text {
        font-size: 32px;
        line-height: 119%;
        padding: 30px 23px 35px;
        max-width: 447px; } }
    @media (max-width: 992px) {
      .help-block__text {
        font-size: 29px;
        line-height: 130%; } }
    @media (max-width: 768px) {
      .help-block__text {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 135%;
        color: #FFFFFF; } }
    .help-block__text--val {
      background: linear-gradient(110.66deg, #9EC271 0.69%, #508909 79.07%);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      color: #FFFFFF; }
    .help-block__text:after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 70px solid #508909;
      border-left: 70px solid transparent;
      position: absolute;
      margin-top: 45px;
      right: 49px; }
      @media (max-width: 1600px) {
        .help-block__text:after {
          margin-top: 35px; } }

.inner {
  padding-bottom: 80px;
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;
  overflow: hidden; }
  @media (max-width: 767px) {
    .inner {
      padding-top: 24px;
      font-size: 18px; } }

.instruction {
  padding: 80px 40px; }
  @media (max-width: 768px) {
    .instruction {
      padding: 40px 15px 72px; } }
  .instruction__title {
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    color: #29235C;
    margin-bottom: 30px; }
    @media (max-width: 1200px) {
      .instruction__title {
        font-size: 44px;
        line-height: 120%; } }
    @media (max-width: 992px) {
      .instruction__title {
        font-size: 40px;
        line-height: 120%; } }
    @media (max-width: 768px) {
      .instruction__title {
        font-weight: 600;
        font-size: 35px;
        line-height: 130%;
        margin-bottom: 24px; } }

.label {
  text-decoration: none;
  padding: 9px 23px;
  border: 1px solid;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.35;
  color: #2ABAEC;
  border-radius: 100px;
  display: inline-block; }

.left-block {
  max-width: 672px; }
  .left-block--val {
    background-color: #A4D369; }
  .left-block--val-plus {
    background-color: #7FCEF6; }
  .left-block__itm {
    padding: 42px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    margin-bottom: 80px; }
    .left-block__itm__title {
      font-weight: 500;
      font-size: 49px;
      line-height: 52px;
      color: #29235C;
      margin-bottom: 25px; }
  .left-block__buy-block__buy-btn {
    margin-bottom: 15px; }
  .left-block__buy-block__disclamer {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #1D1E1F;
    opacity: 0.8;
    margin-bottom: 60px;
    max-width: 584px; }
  .left-block__buy-block__title {
    font-style: normal;
    font-weight: 500;
    font-size: 69px;
    line-height: 70px;
    color: #29235C; }
    .left-block__buy-block__title__block-2 {
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      color: #29235C;
      opacity: 0.5;
      margin-bottom: 16px; }
  .left-block__buy-block__slogan {
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 135%;
    color: #29235C;
    margin-bottom: 8px; }
  .left-block__buy-block__price-descritpion {
    margin-bottom: 75px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
    color: #29235C; }

.tags__list {
  display: flex;
  flex-wrap: wrap; }
  .tags__list a {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    margin: 5px 10px;
    border: 1px solid #8E8F8F;
    border-radius: 50px;
    flex-wrap: wrap;
    padding: 7px 17px;
    text-decoration: none; }

.structure__list__itm {
  border-bottom: 1px solid #ccdcb6; }
  .structure__list__itm__visible {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer; }
  .structure__list__itm:last-child {
    border: none; }
  .structure__list__itm__icon {
    flex-basis: 56px;
    margin-right: 38px;
    overflow: hidden;
    background: #C88DD7;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .structure__list__itm__icon-plus {
      background: #FFA841; }
  .structure__list__itm__name {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    flex-grow: 1; }
  .structure__list__itm__description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155%;
    color: #000000;
    padding-bottom: 10px;
    display: none; }
    .structure__list__itm__description p {
      margin-bottom: 0px; }
    .structure__list__itm__description ul {
      list-style: disc;
      margin-left: 23px; }
    .structure__list__itm__description b {
      font-weight: bold; }
  .structure__list__itm--active .structure__list__itm__plus {
    transform: rotate(45deg) !important; }
  .structure__list__itm--active .structure__list__itm__description {
    display: block; }

.news-card {
  margin: 0 0 60px;
  text-decoration: none;
  opacity: 1;
  display: block;
  transition: opacity .2s;
  color: #000000; }
  @media (max-width: 767px) {
    .news-card {
      margin-bottom: 40px; } }
  .news-card-img {
    border-radius: 30px;
    padding-top: 63.67925%;
    overflow: hidden;
    display: block;
    position: relative;
    background: center / cover no-repeat;
    margin: 0 0 16px; }
    @media (max-width: 1199px) {
      .news-card-img {
        padding-top: 50.72886%; } }
    .news-card-img--book {
      padding-top: 127.2%; }
  .news-card-body {
    font-size: 18px;
    line-height: 155%; }
  .news-card-title {
    margin: 0 0 8px;
    font-weight: 600;
    font-size: 26px;
    line-height: 135%;
    color: #1D1E1F; }
    @media (max-width: 991px) {
      .news-card-title {
        font-size: 22px; } }
  .news-card-author {
    font-size: 18px;
    line-height: 1.55;
    color: #8E8F8F;
    margin-top: 15px; }
    @media (max-width: 991px) {
      .news-card-author {
        margin-top: 8px; } }
  .news-card-datetime {
    min-height: 24px;
    padding-left: 34px;
    font-size: 18px;
    line-height: 155%;
    color: #8E8F8F;
    position: relative; }
    .news-card-datetime:before {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z' fill='%238E8F8F'/%3E%3Cpath d='M12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11.325L7.62901 13.437C7.40357 13.5778 7.24332 13.8024 7.18349 14.0614C7.12367 14.3204 7.16918 14.5926 7.31001 14.818C7.45084 15.0434 7.67545 15.2037 7.93444 15.2635C8.19343 15.3233 8.46557 15.2778 8.69101 15.137L12.531 12.737C12.6761 12.6461 12.7954 12.5195 12.8775 12.3692C12.9596 12.219 13.0018 12.0502 13 11.879V7C13 6.73478 12.8947 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6Z' fill='%238E8F8F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); }
  .news-card-margin {
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .news-card-margin {
        margin-bottom: 40px; } }
  .news-card-container {
    margin-bottom: -60px; }
    @media (max-width: 767px) {
      .news-card-container {
        margin-bottom: -40px; } }
  .news-card:hover {
    opacity: .7; }

/*# sourceMappingURL=bootstrap-grid.css.map */
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav .owl-next[role="button"], .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav .owl-prev[role="button"], .owl-carousel button.owl-dot, .owl-carousel .owl-dot[role="button"] {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item .owl-lazy:not([src]), .owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav {
  margin-top: 10px; }

.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity .2s ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

.page-404 {
  text-align: center;
  margin-top: 126px;
  margin-bottom: 146px; }
  @media (max-width: 768px) {
    .page-404 {
      margin-top: 152px; } }
  .page-404__numbers {
    font-style: normal;
    font-weight: bold;
    font-size: 275.314px;
    line-height: 329px;
    color: #BDE28F;
    display: flex;
    justify-items: center;
    justify-content: center; }
    .page-404__numbers img {
      transform: translateY(17px); }
      @media (max-width: 768px) {
        .page-404__numbers img {
          width: 120px; } }
    @media (max-width: 768px) {
      .page-404__numbers {
        font-size: 150px;
        line-height: 179px; } }
  .page-404__text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 56px;
    font-weight: 600;
    font-size: 26px;
    line-height: 135%;
    color: #1D1E1F; }
    @media (max-width: 768px) {
      .page-404__text {
        font-size: 22px; } }
    .page-404__text img {
      margin-left: 7px; }

.prep-sticky-block {
  margin-top: 45px;
  margin-bottom: 45px;
  position: sticky;
  top: 100px;
  padding: 55px 40px;
  border-radius: 30px;
  text-align: center; }
  .prep-sticky-block--val {
    background: #BDE28F; }
  .prep-sticky-block--val-plus {
    background: #7FCEF6; }
  @media (max-width: 1399px) {
    .prep-sticky-block {
      padding: 35px 20px; } }
  @media (max-width: 1199px) {
    .prep-sticky-block {
      padding: 25px 10px; } }
  .prep-sticky-block__name {
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    text-align: center;
    color: #29235C;
    margin-bottom: 8px; }
    @media (max-width: 1399px) {
      .prep-sticky-block__name {
        font-size: 45px;
        line-height: 47px; } }
    @media (max-width: 1199px) {
      .prep-sticky-block__name {
        font-size: 40px;
        line-height: 43px; } }
    .prep-sticky-block__name span {
      color: #699830; }
  .prep-sticky-block__description {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #29235C;
    opacity: 0.5;
    margin-bottom: 8px; }
  .prep-sticky-block__img {
    text-align: center;
    margin-bottom: 35px; }
    .prep-sticky-block__img--val {
      background-image: url("../img/heart.svg");
      background-repeat: no-repeat;
      background-position: 0px 20px; }
      @media (max-width: 1399px) {
        .prep-sticky-block__img--val {
          background-size: contain; } }
    .prep-sticky-block__img--val-plus {
      background-image: url("../img/heart-plus.svg");
      background-repeat: no-repeat;
      background-position: 0px 20px; }
      @media (max-width: 1399px) {
        .prep-sticky-block__img--val-plus {
          background-size: contain; } }
    .prep-sticky-block__img img {
      margin: 0px auto; }

.read-more {
  margin-top: 60px; }

.rounded-banner {
  background: center / cover no-repeat #BDE28F;
  display: block;
  text-decoration: none;
  color: #FFFFFF;
  border-radius: 30px;
  padding: 105px 40px;
  opacity: 1;
  min-height: 100%;
  transition: opacity .2s; }
  @media (max-width: 767px) {
    .rounded-banner {
      min-height: 366px;
      padding: 45px 40px;
      margin: 0 -15px;
      border-radius: 0; } }
  .rounded-banner__title {
    font-weight: 500;
    font-size: 49px;
    line-height: 1.06;
    text-align: center; }
    @media (max-width: 1199px) {
      .rounded-banner__title {
        font-size: 26px;
        line-height: 1.35; } }
  .rounded-banner:hover {
    opacity: .7; }

.sertifications {
  margin-bottom: 60px; }
  .sertifications__itm {
    display: flex;
    padding: 24px 0px;
    align-items: center;
    border-bottom: 1px solid #eae9ef; }
    .sertifications__itm:last-child {
      border-bottom: none; }
    .sertifications__itm__text-link {
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 135%;
      color: #29235C;
      flex-grow: 1;
      transition: 0.3s ease all;
      text-align: left; }
      @media (max-width: 1399px) {
        .sertifications__itm__text-link {
          font-size: 24px; } }
      @media (max-width: 1199px) {
        .sertifications__itm__text-link {
          font-size: 21px; } }
      @media (max-width: 767px) {
        .sertifications__itm__text-link {
          font-size: 18px;
          line-height: 140%;
          text-align: left; } }
      .sertifications__itm__text-link:hover {
        opacity: 0.8; }
    .sertifications__itm__text-link-download {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #699830;
      flex: none;
      order: 1;
      flex-grow: 0; }
      .sertifications__itm__text-link-download:hover {
        text-decoration: underline; }
    .sertifications__itm__icon {
      width: 88px;
      height: 88px;
      margin-right: 36px;
      background: #699830;
      border-radius: 30px;
      justify-content: center;
      align-items: center;
      display: flex; }
      @media (max-width: 767px) {
        .sertifications__itm__icon {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          margin-right: 14px;
          border-radius: 50%; } }
      @media (max-width: 767px) {
        .sertifications__itm__icon svg {
          width: 13px;
          height: 15px; } }

.tabs {
  border-bottom: 1px solid rgba(41, 35, 92, 0.5);
  display: flex;
  align-items: stretch;
  justify-content: flex-start; }

.tab {
  padding: 15px 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;
  color: #29235C;
  opacity: 0.5;
  text-align: center;
  margin-bottom: -1.5px;
  cursor: pointer; }
  @media (max-width: 767px) {
    .tab {
      font-size: 18px; } }
  .tab.active {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #29235C !important;
    border-bottom: 3px solid !important;
    opacity: 1;
    transition: 0.3s ease all; }
    @media (max-width: 767px) {
      .tab.active {
        font-size: 18px; } }
  @media (max-width: 1199px) {
    .tab {
      padding: 15px 20px; } }
  @media (max-width: 767px) {
    .tab {
      padding: 15px 10px; } }
  .tab:first-child {
    padding-left: 0px; }
  .tab_item {
    margin-top: 49px;
    display: none; }
    .tab_item:first-child {
      display: block; }

.tests {
  font-weight: 500;
  font-size: 22px;
  line-height: 135%; }
  @media (max-width: 767px) {
    .tests {
      font-size: 18px; } }
  .tests-title {
    color: #29235C;
    margin: 0 0 50px; }
    @media (max-width: 767px) {
      .tests-title {
        font-size: 35px;
        line-height: 130%; } }
  .tests-list {
    padding: 0 0 30px; }
  .tests-card {
    margin: 0 0 30px;
    text-decoration: none;
    opacity: 1;
    display: block;
    transition: opacity .2s; }
    .tests-card-img {
      border-radius: 30px;
      position: relative;
      padding: 31px 33px;
      height: 270px;
      overflow: hidden;
      display: block;
      position: relative;
      margin: 0 0 16px;
      color: #fff; }
      @media (max-width: 767px) {
        .tests-card-img {
          height: 174px;
          padding: 17px; } }
      .tests-card-img--valemidin {
        background: #2B7CBC; }
        .tests-card-img--valemidin__img {
          width: 298px;
          height: 165px;
          position: absolute;
          bottom: 16px;
          right: 16px; }
          @media (max-width: 1399px) {
            .tests-card-img--valemidin__img {
              width: 198px;
              height: 109px; } }
          @media (max-width: 991px) {
            .tests-card-img--valemidin__img {
              width: 145px;
              height: 94px; } }
          @media (max-width: 767px) {
            .tests-card-img--valemidin__img {
              bottom: 6px; } }
      .tests-card-img--morin {
        background: linear-gradient(80.61deg, #478504 6.53%, #6CBE13 90.53%); }
        .tests-card-img--morin__img {
          position: absolute;
          bottom: 32px;
          right: 32px; }
          @media (max-width: 767px) {
            .tests-card-img--morin__img {
              bottom: 17px;
              right: 17px; } }
      .tests-card-img--scale {
        background: linear-gradient(77.05deg, #3F044F 7.35%, #823297 127.92%); }
        .tests-card-img--scale__img {
          position: absolute;
          bottom: 32px;
          right: 32px; }
          @media (max-width: 767px) {
            .tests-card-img--scale__img {
              bottom: 17px;
              right: 17px; } }
      .tests-card-img--bek {
        background: linear-gradient(243.98deg, #2B3B8B 8.16%, #29235C 99.25%); }
        .tests-card-img--bek__img {
          position: absolute;
          bottom: 32px;
          right: 32px; }
          @media (max-width: 991px) {
            .tests-card-img--bek__img svg {
              width: 145px; } }
          @media (max-width: 767px) {
            .tests-card-img--bek__img {
              bottom: 17px;
              right: 17px; } }
      .tests-card-img img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .tests-card-title {
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      line-height: 135%;
      color: #F8F8F8; }
      @media (max-width: 1199px) {
        .tests-card-title {
          font-size: 28px; } }
      @media (max-width: 767px) {
        .tests-card-title {
          font-size: 22px; } }
    .tests-card-datetime {
      padding: 4px 10px 4px 44px;
      position: absolute;
      height: 36px;
      left: 28px;
      bottom: 32px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 29px;
      font-size: 18px;
      line-height: 155%; }
      @media (max-width: 767px) {
        .tests-card-datetime {
          height: 20px;
          left: 17px;
          bottom: 17px;
          font-size: 14px;
          padding: 0px 10px 0px 30px; } }
      .tests-card-datetime:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        top: 6px;
        left: 10px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z' fill='%23fff'/%3E%3Cpath d='M12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11.325L7.62901 13.437C7.40357 13.5778 7.24332 13.8024 7.18349 14.0614C7.12367 14.3204 7.16918 14.5926 7.31001 14.818C7.45084 15.0434 7.67545 15.2037 7.93444 15.2635C8.19343 15.3233 8.46557 15.2778 8.69101 15.137L12.531 12.737C12.6761 12.6461 12.7954 12.5195 12.8775 12.3692C12.9596 12.219 13.0018 12.0502 13 11.879V7C13 6.73478 12.8947 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6Z' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); }
        @media (max-width: 767px) {
          .tests-card-datetime:before {
            width: 14px;
            height: 14px;
            top: 3px;
            left: 9px; } }
    .tests-card__description {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 155%;
      color: #1D1E1F; }
    .tests-card:hover {
      opacity: .7; }
      .tests-card:hover__description {
        color: #1D1E1F; }
  .tests-test {
    color: #FFFFFF;
    border-radius: 30px;
    padding: 40px 60px 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    background: #E58E7D;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 0 56px; }
    @media (max-width: 767px) {
      .tests-test {
        padding: 20px 15px;
        margin: 0 -15px 56px; } }
    .tests-test-title {
      font-weight: 500;
      font-size: 49px;
      line-height: 52px;
      margin: 0 auto 45px;
      width: 100%;
      padding: 0 230px;
      position: relative; }
      @media (max-width: 1229px) {
        .tests-test-title {
          padding: 0; } }
      @media (max-width: 767px) {
        .tests-test-title {
          font-size: 26px;
          line-height: 135%;
          margin: 0 auto 15px; } }
      .tests-test-title:before, .tests-test-title:after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        clear: both; }
    .tests-test-all {
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #FFFFFF;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      position: absolute;
      left: 0;
      top: 0;
      padding: 6px 24px;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 20px; }
      @media (max-width: 1229px) {
        .tests-test-all {
          position: relative;
          margin: 28px auto 4px;
          width: 270px;
          justify-content: center;
          padding: 16px 42px;
          font-weight: 500;
          font-size: 18px;
          line-height: 155%;
          text-align: center;
          letter-spacing: 0.03em;
          text-transform: uppercase; } }
      .tests-test-all svg {
        margin-left: 8px; }
    .tests-test-pager {
      display: inline-block;
      padding: 4px 14px;
      background: rgba(255, 255, 255, 0.2) !important;
      border-radius: 36px;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      margin: 0 auto 40px; }
      @media (max-width: 767px) {
        .tests-test-pager {
          font-size: 18px;
          margin: 0 auto 10px; } }
      .tests-test-pager__del, .tests-test-pager__end {
        opacity: .5; }
    .tests-test-question {
      font-weight: 600;
      font-size: 35px;
      line-height: 135%;
      margin: 0 auto 15px; }
      @media (max-width: 767px) {
        .tests-test-question {
          font-size: 22px;
          margin: 0 auto 25px; } }
    .tests-test-answers {
      width: 100%; }
    .tests-test-answer {
      min-height: 100%;
      font-weight: 500;
      font-size: 22px;
      line-height: 135%;
      color: #29235C;
      background: #FFFFFF;
      border-radius: 30px;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
      text-align: center;
      text-decoration: none;
      opacity: 1;
      transition: opacity .2s;
      margin: 0 0 25px; }
      @media (max-width: 767px) {
        .tests-test-answer {
          flex-direction: row;
          min-height: 110px;
          font-size: 18px;
          line-height: 140%;
          margin: 0 0 12px;
          text-align: left; } }
      .tests-test-answer-img {
        background: center / cover no-repeat;
        padding-top: 60%;
        display: block;
        border-radius: 30px;
        overflow: hidden; }
        @media (max-width: 767px) {
          .tests-test-answer-img {
            flex: 0 0 179px;
            padding-top: 0;
            align-self: stretch; } }
      .tests-test-answer-text {
        margin: auto;
        padding: 20px 25px 20px; }
        @media (max-width: 767px) {
          .tests-test-answer-text {
            flex: 1 1 0; } }
      .tests-test-answer:hover {
        opacity: .7; }
      .tests-test-answer--center {
        text-align: center;
        min-height: auto;
        background: white; }
  .tests-more {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 42px;
    border: 1px solid #478504;
    box-sizing: border-box;
    border-radius: 30px;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    max-width: 310px;
    color: #478504;
    cursor: pointer;
    transition: opacity .2s;
    margin: 0 auto; }
    .tests-more:hover {
      opacity: .7; }
    @media (max-width: 767px) {
      .tests-more {
        padding: 16px 42px;
        font-size: 18px;
        border-radius: 25px; } }
  .tests-back {
    display: flex;
    text-decoration: none;
    align-items: center;
    font-weight: 500;
    font-size: 22px;
    line-height: 135%;
    color: #1D1E1F;
    opacity: 0.5;
    margin: 0 0 56px; }
    @media (max-width: 767px) {
      .tests-back {
        font-size: 18px;
        line-height: 140%;
        margin: 0 0 32px; } }
    .tests-back svg {
      margin-right: 12px; }
      @media (max-width: 767px) {
        .tests-back svg {
          width: 4px;
          margin-right: 8px; } }
  .tests-faq {
    border-top: 1px solid rgba(41, 35, 92, 0.08);
    border-bottom: 1px solid rgba(41, 35, 92, 0.08); }
    .tests-faq li.q {
      font-weight: 600;
      font-size: 22px;
      line-height: 135%;
      color: #29235C;
      background-color: transparent;
      border-radius: 0; }
      .tests-faq li.q ~ li.q {
        border-top: 1px solid rgba(41, 35, 92, 0.08); }
  .tests .tabs__wrapper {
    display: flex; }
  .tests .dop-res, .tests .test-res-var {
    display: none; }
  .tests .ans-button {
    margin: 0 8px 0; }
  .tests .ans-wrap {
    justify-content: center;
    align-items: center; }
  .tests .tests-test-answer--center {
    background: white; }
  .tests .ans-button.sel > a {
    background: #f1baba; }
  .tests .ans-next > a {
    background: #478504;
    text-transform: uppercase;
    color: white;
    position: relative; }
    .tests .ans-next > a > div {
      padding: 20px 40px; }
    .tests .ans-next > a:after {
      content: ' ';
      display: block;
      width: 7px;
      height: 14px;
      background: url("/images/next.svg") 50% no-repeat;
      position: absolute;
      right: 24px;
      top: 27px; }
  .tests .tests-test {
    background: url("/images/heart.png") 50% -200px no-repeat, linear-gradient(243.98deg, #2B3B8B 8.16%, #29235C 99.25%) !important; }
  .tests .test-body--ending {
    text-align: left;
    max-width: 80%;
    padding: 0; }

.tests-test.tests-cats {
  background: #E58E7D !important; }

.tests-cats {
  position: relative; }
  .tests-cats .b-heart-1 {
    position: absolute;
    top: 85px;
    left: 106px; }
    @media (max-width: 767px) {
      .tests-cats .b-heart-1 {
        display: none; } }
  .tests-cats .b-heart-2 {
    position: absolute;
    top: 130px;
    left: 230px; }
    @media (max-width: 767px) {
      .tests-cats .b-heart-2 {
        display: none; } }
  .tests-cats .b-heart-3 {
    position: absolute;
    top: 80px;
    right: 80px; }
    @media (max-width: 767px) {
      .tests-cats .b-heart-3 {
        display: none; } }
  .tests-cats .test-body--ending {
    max-width: 100%;
    display: flex; }
    .tests-cats .test-body--ending > div {
      width: 50%; }
    @media (max-width: 991px) {
      .tests-cats .test-body--ending {
        flex-direction: column; }
        .tests-cats .test-body--ending > div {
          width: 100%; } }
  .tests-cats .tests-test-answers-inner > div {
    margin-bottom: 25px; }
  .tests-cats .tests-test-answers {
    max-width: 900px; }
  .tests-cats .tests-test-answer {
    margin-bottom: 0;
    height: 100%; }
  @media (max-width: 767px) {
    .tests-cats .tests-test-answer-img {
      background-size: cover; } }
  @media (max-width: 575px) {
    .tests-cats .tests-test-answer-img {
      flex: 0 0 140px; } }
  @media (max-width: 575px) {
    .tests-cats .tests-test-answer-text {
      padding: 20px 10px;
      font-size: 16px;
      line-height: 1.2; } }

.tests-cats-result {
  padding: 0; }
  .tests-cats-result .b-heart-1, .tests-cats-result .b-heart-2, .tests-cats-result .b-heart-3 {
    display: none; }
  @media (max-width: 767px) {
    .tests-cats-result .test-header--ending {
      text-align: center; }
      .tests-cats-result .test-header--ending > div {
        width: 100%; } }
  .tests-cats-result .test-body__inner-left {
    padding: 40px; }
    .tests-cats-result .test-body__inner-left .test-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
    @media (max-width: 767px) {
      .tests-cats-result .test-body__inner-left {
        padding: 20px; } }
  @media (max-width: 991px) {
    .tests-cats-result {
      max-width: 687px; } }

h1, h2, h3 .h1, .h2, .h3 {
  font-style: normal;
  font-weight: 500; }

.h4, .h5, .h6, h4, h5, h6 {
  font-style: normal;
  font-weight: 600; }

h1, .h1 {
  font-weight: 500;
  font-size: 96px;
  line-height: 115px; }

h2, .h2 {
  font-size: 69px;
  line-height: 70px;
  margin-bottom: 40px; }
  @media (max-width: 1199px) {
    h2, .h2 {
      font-size: 57px; } }
  @media (max-width: 991px) {
    h2, .h2 {
      font-size: 45px;
      line-height: 130%; } }
  @media (max-width: 767px) {
    h2, .h2 {
      font-size: 35px;
      line-height: 130%;
      margin-bottom: 30px; } }

h3, .h3 {
  font-size: 49px;
  line-height: 52px; }
  @media (max-width: 767px) {
    h3, .h3 {
      font-weight: 600;
      font-size: 35px;
      line-height: 130%; } }

h4, .h4 {
  font-size: 35px;
  line-height: 135%;
  margin-top: 30px;
  margin-bottom: 16px; }
  @media (max-width: 1199px) {
    h4, .h4 {
      font-size: 28px; } }
  @media (max-width: 991px) {
    h4, .h4 {
      font-size: 22px; } }
  @media (max-width: 767px) {
    h4, .h4 {
      font-size: 18px;
      line-height: 140%; } }

h5, .h5 {
  font-size: 26px;
  line-height: 135%; }

h6, .h6 {
  font-size: 22px;
  line-height: 135%; }

.text-dark-blue {
  color: #29235C; }

.text-dark-green {
  color: #478504; }

.recommendation {
  display: flex;
  align-items: center;
  margin-bottom: 24px; }
  .recommendation__itm {
    display: flex;
    align-items: center;
    margin-right: 40px; }
    .recommendation__itm svg {
      margin-right: 15px; }

.text {
  font-weight: 500;
  font-size: 22px;
  line-height: 135%; }
  .text ul {
    list-style: disc;
    margin-left: 20px; }
  @media (max-width: 1199px) {
    .text {
      font-size: 21px; } }
  @media (max-width: 991px) {
    .text {
      font-size: 20px; } }
  @media (max-width: 767px) {
    .text {
      font-size: 18px;
      line-height: 130%; } }

.use-information {
  max-width: 882px;
  margin: 0px auto 40px;
  font-weight: 500;
  font-size: 22px;
  line-height: 135%;
  color: #1D1E1F;
  text-align: left; }
  @media (max-width: 1199px) {
    .use-information {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      color: #1D1E1F; } }
  @media (max-width: 767px) {
    .use-information {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      color: #1D1E1F; } }
  .use-information h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 52px;
    color: #29235C;
    margin-bottom: 46px; }
    @media (max-width: 767px) {
      .use-information h1 {
        font-weight: 500;
        font-size: 22px;
        line-height: 135%;
        margin-bottom: 32px;
        text-align: center; } }
    @media (max-width: 767px) {
      .use-information h1 {
        font-weight: 500;
        font-size: 22px;
        line-height: 135%;
        margin-bottom: 32px;
        text-align: center; } }
  .use-information ol {
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    color: #1D1E1F;
    list-style: auto;
    margin-left: 30px; }
    @media (max-width: 767px) {
      .use-information ol {
        margin-left: 20px; } }
    .use-information ol li {
      margin-bottom: 20px; }
      @media (max-width: 767px) {
        .use-information ol li {
          margin-bottom: 10px; } }
      .use-information ol li ul {
        list-style: disc;
        margin-top: 20px;
        margin-left: 60px; }
        @media (max-width: 767px) {
          .use-information ol li ul {
            margin-top: 10px;
            margin-left: 40px; } }
        .use-information ol li ul li {
          margin-bottom: 3px; }

.v-help {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  padding: 74px 0 48px;
  position: relative; }
  @media (max-width: 1199px) {
    .v-help {
      padding: 77px 0 0;
      align-items: center; } }
  .v-help-bg {
    background: #C27ED3;
    position: relative;
    overflow: hidden; }
  .v-help-rect {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 50%; }
    @media (max-width: 1199px) {
      .v-help-rect {
        top: 0;
        width: 100%;
        left: 0;
        right: 0;
        height: 50%; } }
  .v-help-img {
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    width: auto;
    max-width: none;
    transform: translateX(-50%); }
    @media (max-width: 1199px) {
      .v-help-img {
        position: initial;
        max-width: none;
        height: auto;
        transform: none;
        margin-top: -110px; } }
  .v-help-message {
    z-index: 1;
    padding: 35px 35px 90px;
    font-size: 49px;
    line-height: 1.06;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    width: 583px;
    max-width: 100%; }
    @media (max-width: 1199px) {
      .v-help-message {
        width: 335px;
        font-weight: 600;
        font-size: 26px;
        line-height: 1.2;
        padding: 35px 35px 57px; } }
    .v-help-message svg, .v-help-message img {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 100%;
      z-index: -1; }
      @media (max-width: 1199px) {
        .v-help-message svg, .v-help-message img {
          top: 0;
          right: 0; } }
    .v-help-message--1 {
      padding: 64px 35px 65px 48px; }
      @media (max-width: 1199px) {
        .v-help-message--1 {
          padding: 35px 35px 57px; } }
  .v-help-logo {
    margin-bottom: 60px;
    position: relative;
    max-width: 100%; }
    @media (max-width: 1199px) {
      .v-help-logo {
        max-width: 231px;
        margin-bottom: 26px; } }
    .v-help-logo--1 {
      margin-bottom: 40px; }
      @media (max-width: 1199px) {
        .v-help-logo--1 {
          margin-bottom: 26px; } }
  .v-help-props {
    position: relative;
    width: 600px;
    margin-top: 60px;
    max-width: 100%;
    margin-bottom: 8px; }
    @media (max-width: 1199px) {
      .v-help-props {
        margin-top: 0;
        max-width: none;
        width: auto; } }
    .v-help-props--1 {
      margin-top: 30px; }
      @media (max-width: 1199px) {
        .v-help-props--1 {
          margin-top: 0; } }
  .v-help-prop-title {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: #29235C; }
    @media (max-width: 1199px) {
      .v-help-prop-title {
        margin-bottom: 16px; } }
  .v-help-prop {
    position: relative;
    padding-left: 32px;
    background-image: url(../img/icon-li-agree--green.svg);
    background-repeat: no-repeat;
    background-position: 0 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: #29235C;
    margin-bottom: 16px;
    min-height: 22px; }
    @media (max-width: 1199px) {
      .v-help-prop {
        font-weight: normal;
        font-size: 16px;
        line-height: 140%; } }

.val-btns {
  margin: 45px -8px 72px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767px) {
    .val-btns {
      margin: 25px -5px 25px; } }
  .val-btns > .button {
    margin: 0 8px 8px; }
    @media (max-width: 767px) {
      .val-btns > .button {
        margin: 0 5px 6px; } }
